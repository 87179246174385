import { flow, types as t, getRoot } from 'mobx-state-tree';
import axios from 'axios';

const IndicationFilterModel = t
  .model('IndicationFilterModel', {
    list: t.optional(t.array(t.string), []),
    isLoading: t.optional(t.boolean, false),
    selectedIndication: t.optional(t.string, ''),
    savedIndicationName: t.optional(t.string, ''),
    errorMessage: t.optional(t.string, ''),
  })
  .actions((self) => ({
    fetchIndications: flow(function* fetchIndications(indicationName) {
      try {
        self.isLoading = true;

        const params = {
          indicationName,
        };

        const { data } = yield axios.get('/indications/v1/matching', { params });

        self.list = data;
      } catch (error) {
        //
      } finally {
        self.isLoading = false;
      }
    }),
    editIndication: flow(function* editIndication(indicationName, onSuccessCb) {
      try {
        const { savedIndicationName, saveIndicationName } = self;

        const {
          therapies: { fetchTherapies },
        } = getRoot(self);

        const config = {
          newName: indicationName,
          oldName: savedIndicationName,
        };

        yield axios.patch('/indications/v1', config);

        onSuccessCb();

        saveIndicationName('');

        fetchTherapies();
      } catch (error) {
        self.errorMessage = error?.response?.data?.detail || error.message || 'Server error';
      } finally {
        self.isLoading = false;
      }
    }),
    deleteIndication: flow(function* deleteIndication(onSuccessCb) {
      const { savedIndicationName } = self;
      const {
        therapies: { fetchTherapies },
      } = getRoot(self);

      try {
        const params = {
          name: savedIndicationName,
        };

        yield axios.delete('/indications/v1', { params });

        onSuccessCb();
        fetchTherapies();
      } catch (error) {
        //
      }
    }),
    cleanErrors() {
      self.errorMessage = '';
    },
    saveIndicationName(indicationName) {
      self.savedIndicationName = indicationName;
    },
    onSelectIndication(indicationName) {
      self.selectedIndication = indicationName;

      const {
        therapies: { fetchTherapies, changePage },
      } = getRoot(self);

      changePage(0);
      fetchTherapies();
    },
  }));

export default IndicationFilterModel;
