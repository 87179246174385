import axios from 'axios';
import { toast } from 'react-toastify';

const AxiosConfig = () => {
  const token = localStorage.getItem('ls_token');

  // setting axios defaults
  axios.defaults.baseURL = '/api';
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;

  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { status } = error.response;
      let showError = true;

      if (status === 401) {
        if (error.response.data.detail) {
          showError = false;
          window.location.href = error.response.data.detail;
        }
      }

      const errMessage =
        error?.response?.data?.detail || error.response.data.title || error.message;

      if (showError) {
        toast.error(errMessage);
      }

      return Promise.reject(error);
    },
  );
};

export default AxiosConfig;
