import React from 'react';
import { types, addMiddleware, applySnapshot, getSnapshot } from 'mobx-state-tree';
import { persist } from 'mst-persist';
import { mstLog } from 'mst-log';
import AuthUserModel from './AuthUserModel';
import TherapiesModel from './TherapiesModel';
import FiltersModel from './FiltersModel';
import DrugCreatingModel from './DrugCreatingModel';
import AppModel from './AppModel';

/**
 * @typedef {Object} RootStore
 // * @property {AuthUserModel} user
 */
const RootStore = types
  .model('RootStore', {
    login: types.optional(AuthUserModel, {}),
    therapies: types.optional(TherapiesModel, {}),
    filters: types.optional(FiltersModel, {}),
    drugCreating: types.optional(DrugCreatingModel, {}),
    app: types.optional(AppModel, {}),
  })
  .actions((self) => {
    let initialState = {};
    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      reset: () => {
        applySnapshot(self, initialState);
      },
      clearData() {
        applySnapshot(self, initialState);
      },
    };
  });

const initialState = RootStore.create({});
if (process.env.NODE_ENV !== 'production') {
  addMiddleware(initialState, mstLog());
}

export const rootStore = initialState;

persist('RootModel', rootStore, {
  storage: localStorage,
  jsonify: true,
  whitelist: ['login'],
  // eslint-disable-next-line no-console
}).then(() => console.log('RootModel has been hydrated'));

/**
 * RootStore context
 * @type {React.Context<RootStore | null>}
 */
export const RootStoreContext = React.createContext(null);

export function useRootModel() {
  const store = React.useContext(RootStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return store;
}
