import React from 'react';
import { Navigate } from 'react-router-dom';

import Loader from '../../components/Loader';

const Login = ({ isAuth }) => {
  return (
    <div
      style={{ display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center' }}
    >
      {isAuth && <Navigate to='/' />}
      <Loader size={60} />
    </div>
  );
};

export default Login;
