import { types as t } from 'mobx-state-tree';

const TimeAssumptionsModel = t.model('TimeAssumptionsModel', {
  days: t.optional(t.maybeNull(t.number), 0),
  weeks: t.optional(t.maybeNull(t.number), 0),
  months: t.optional(t.maybeNull(t.number), 0),
  years: t.optional(t.maybeNull(t.number), 0),
  hours: t.optional(t.maybeNull(t.number), 0),
});

export default TimeAssumptionsModel;
