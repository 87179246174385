import { types } from 'mobx-state-tree';

const PhasesModel = types
  .model('PhasesModel', {
    activeIngredientAmount: types.maybeNull(types.union(types.string, types.number)),
    frequency: types.maybeNull(types.number),
    frequencyDuration: types.maybeNull(types.string),
    id: types.optional(types.maybeNull(types.number), null),
    name: types.maybeNull(types.string),
    numberOfTimes: types.maybeNull(types.number),
    periodDuration: types.optional(types.maybeNull(types.string), null),
    periodNumber: types.maybeNull(types.number),
    unit: types.optional(types.maybeNull(types.string), null),
    active: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setActive() {
      self.active = true;
    },
    setDisable() {
      self.active = false;
    },
    onChangeActiveAmount(value) {
      self.activeIngredientAmount = value;
    },
    onChangeUnit(unit) {
      self.unit = unit;
    },
    onChangeNumberOfTimes(numberOfTimes) {
      self.numberOfTimes = numberOfTimes;
    },
    onChangeFrequency(frequency) {
      self.frequency = frequency;
    },
    onChangeFrequencyDuration(frequencyDuration) {
      self.frequencyDuration = frequencyDuration;
    },
    onChangePeriodNumber(periodNumber) {
      self.periodNumber = periodNumber;
    },
    onChangePeriodDuration(periodDuration) {
      self.periodDuration = periodDuration;
    },
  }));

export default PhasesModel;
