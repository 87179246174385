import React from 'react';
import { Tooltip } from 'antd';

import cn from 'classnames';
import styles from './styles.module.scss';

const TextButton = ({
  text,
  position = 'left',
  onClick,
  className,
  disabled,
  LoaderComponent,
  isLoading,
  children,
  tooltipText,
}) => {
  const handleClick = (e) => {
    if (!disabled) {
      onClick(e);
    }
  };

  const childNode = tooltipText ? (
    <Tooltip placement='right' title={tooltipText}>
      <button
        type='button'
        // disabled={disabled}
        onClick={handleClick}
        style={{ textAlign: position }}
        className={cn(styles.button, className, disabled && styles.disabled)}
      >
        {text}
      </button>
    </Tooltip>
  ) : (
    <button
      type='button'
      disabled={disabled}
      onClick={onClick}
      style={{ textAlign: position }}
      className={cn(styles.button, className)}
    >
      {children || text}
    </button>
  );

  const node = isLoading ? LoaderComponent : childNode;

  return node;
};

export default TextButton;
