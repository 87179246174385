import { flow, getRoot, types as t } from 'mobx-state-tree';
import axios from 'axios';

import DrugFilterModel from './DrugFilterModel';
import CountryFilterModel from './CountryFilterModel';
import IndicationFilterModel from './IndicationFilterModel';

const FiltersModel = t
  .model('FiltersModel', {
    countries: t.optional(CountryFilterModel, {}),
    indications: t.optional(IndicationFilterModel, {}),
    drugs: t.optional(DrugFilterModel, {}),
    errorMessage: t.optional(t.string, ''),
    isLoading: t.optional(t.boolean, false),
  })
  .actions((self) => ({
    createIndication: flow(function* createIndication(indicationName, onSuccessCb) {
      try {
        const config = {
          name: indicationName,
        };
        self.isLoading = true;

        yield axios.post('/indications/v1', config);

        onSuccessCb();
      } catch (error) {
        self.errorMessage = error?.response?.data?.detail || error.message || 'Server error';
      } finally {
        self.isLoading = false;
      }
    }),
    cleanErrors() {
      self.errorMessage = '';
    },
  }))
  .views((self) => ({
    get canCreateNewTherapy() {
      const {
        countries: { selectedCountry },
        indications: { selectedIndication },
        drugs: { selectedDrug },
      } = self;

      const {
        therapies: { therapies },
      } = getRoot(self);

      return !!(selectedCountry && selectedIndication && selectedDrug && therapies.length === 0);
    },
  }));

export default FiltersModel;
