import { types as t } from 'mobx-state-tree';

const AppModel = t
  .model('AppModel', {
    isConfirmCloseModalVisible: t.optional(t.boolean, false),
  })
  .actions((self) => ({
    showConfirmCloseModal() {
      self.isConfirmCloseModalVisible = true;
    },
    closeConfirmCloseModal() {
      self.isConfirmCloseModalVisible = false;
    },
  }));

export default AppModel;
