import React, { useMemo, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import ModalBase from '../ModalBase';
import { useRootModel } from '../../../models/rootStore';
import InputBase from '../../InputBase';

import styles from './styles.module.scss';
import AppButton from '../../ui/AppButton';
import Complete from '../../Complete';
import SkusList from '../../SkusList';
import TextButton from '../../buttons/TextButton';
import Dosing from '../../Dosing';
import Loader from '../../Loader';
import AppSelect from '../../ui/AppSelect';
import ConfirmCloseModal from '../ConfirmCloseModal';

const NewDrugModal = ({ visible, setVisible }) => {
  const [step, setStep] = useState(1);
  const [dosingVisible, setDosingVisible] = useState(false);
  const [skusVisible, setSkusVisible] = useState(false);
  const [canCreateTherapy, setCanCreateTherapy] = useState(false);

  const DRUG_NAME_LENGTH = 2;

  const validationSchema = useMemo(() => {
    return Yup.object({
      drugName: Yup.string()
        .required('Drug name is a required field.')
        .min(DRUG_NAME_LENGTH, 'Name for Drug name should be more than 1 symbol.'),
      skus: Yup.array().of(
        Yup.object().shape({
          // currency: Yup.string().required(),
          other: Yup.string().nullable(),
          packageUnit: Yup.number().min(0.1).required(),
          pricePerUnit: Yup.string().min(0.1).required(),
          strength: Yup.number().min(0.1).required(),
          strengthUnit: Yup.string().required(),
          todaysDate: Yup.string().required(),
          type: Yup.string().required(),
          unit: Yup.number().min(0.1).required(),
        }),
      ),
      phases: Yup.array().of(
        Yup.object().shape({
          activeIngredientAmount: Yup.number().min(0.1).required(),
          frequency: Yup.number().min(0.1).required(),
          frequencyDuration: Yup.string().required(),
          numberOfTimes: Yup.number().min(0.1).required(),
          periodDuration: Yup.string().required(),
          periodNumber: Yup.number().min(0.1).required(),
          unit: Yup.string().required(),
        }),
      ),
      indicationName:
        dosingVisible || skusVisible || canCreateTherapy
          ? Yup.string().required()
          : Yup.string().nullable(),
      countryName:
        dosingVisible || skusVisible || canCreateTherapy
          ? Yup.string().required()
          : Yup.string().nullable(),
    });
  }, [dosingVisible, skusVisible, canCreateTherapy]);

  const {
    drugCreating: {
      isLoading,
      onSubmitLoading,
      cleanErrors,
      createDrug,
      errorMessage,
      createEmptySku,
      currentSkus,
      cleanSkus,
      currentDrug: { dosage },
      startScrappingForNotSaved,
      createDrugWithParams,
      deleteSku,
    },
    therapies: { fetchTherapies },
    filters: { indications, countries },
    app: { showConfirmCloseModal },
  } = useRootModel();

  const {
    handleSubmit,
    values,
    handleChange,
    errors,
    setErrors,
    setFieldValue,
    validateForm,
    validateField,
    dirty,
    resetForm,
  } = useFormik({
    initialValues: {
      drugName: '',
      skus: currentSkus,
      indicationName: '',
      countryName: '',
      phases: dosage.phases,
    },
    validationSchema,
    onSubmit: async (formikValues) => {
      try {
        await validateForm();

        if (dosingVisible || skusVisible || canCreateTherapy) {
          createDrugWithParams(formikValues.drugName, () => {
            setStep(4);
          });

          return;
        }

        createDrug(formikValues.drugName, () => {
          setStep(4);
        });
      } catch (err) {
        // console.log(err);
      }
    },
  });

  const onDeleteSku = (id) => {
    deleteSku(id);

    validateForm();
  };

  const addDosing = () => {
    setDosingVisible(true);

    dosage.addNewPhase({ needActive: true });
  };

  const onClose = () => {
    if (errorMessage) {
      cleanErrors();
    }
    setErrors({});

    setStep(1);
    cleanSkus();
    setDosingVisible(false);
    setSkusVisible(false);
    dosage.cleanFields();
    resetForm({
      drugName: null,
      skus: [],
      indicationName: null,
      countryName: null,
      phases: [],
    });

    setVisible(false);
    fetchTherapies();
  };

  const validateFieldCb = async (fieldName) => {
    await validateField(fieldName);
  };

  const onCreateEmptySku = () => {
    createEmptySku(() => {
      if (step !== 2) {
        setSkusVisible(true);
      }
    });
  };

  const modalWidth = useMemo(() => {
    switch (step) {
      case 4:
        return 404;
      default:
        return 1281;
    }
  }, [step]);

  const startScrapping = () => {
    startScrappingForNotSaved();
  };

  const onClickClose = () => {
    if (dirty) {
      showConfirmCloseModal();
    } else {
      onClose();
    }
  };

  useEffect(() => {
    if (errorMessage) {
      cleanErrors();
    }
  }, [values]);

  useEffect(() => {
    if (errorMessage) {
      setErrors({ drugName: errorMessage });
    }

    if (!errorMessage) {
      setErrors({});
    }
  }, [errorMessage]);

  useEffect(() => {
    if (
      values.drugName.length >= DRUG_NAME_LENGTH &&
      (values.indicationName.length > 0 || values.countryName.length > 0)
    ) {
      setCanCreateTherapy(true);
    } else {
      setCanCreateTherapy(false);
    }
  }, [values.drugName, values.countryName, values.indicationName]);

  const isAddDosingButtonDisabled = useMemo(() => {
    return (
      values.drugName.length < DRUG_NAME_LENGTH || !values.indicationName || !values.countryName
    );
  }, [values.indicationName, values.drugName, values.countryName]);

  return (
    <ModalBase
      showCloseIcon={step !== 4}
      width={modalWidth}
      visible={visible}
      onClose={onClickClose}
    >
      <ConfirmCloseModal onClose={onClose} />
      {step === 1 || step === 2 || step === 3 ? (
        <div className={styles.container}>
          <h2>New drug</h2>
          <form onSubmit={handleSubmit}>
            <div className={styles.headerContainer}>
              <InputBase
                className={styles.inputHeader}
                placeholder='Drug name'
                legend='Drug name'
                name='drugName'
                type='text'
                handleChange={handleChange}
                onChange={(e) => {
                  handleChange(e);

                  dosage.onSelectDrugName(e.target.value);
                }}
                value={values.drugName}
                error={errors.drugName}
              />

              <AppSelect
                error={errors.countryName}
                fieldsetClassName={styles.country}
                loading={countries.isLoading}
                placeholder='Country'
                legend='Country'
                listItems={countries.list}
                size='large'
                onSearch={countries.fetchCountries}
                onSelectCb={async (value) => {
                  await setFieldValue('countryName', value, false);

                  await validateFieldCb('countryName');

                  dosage.onSelectCountryName(value);
                }}
                onCleanCb={dosage.onSelectCountryName}
                // onChange={dosage.onSelectCountryName}
              />

              <AppSelect
                error={errors.indicationName}
                fieldsetClassName={styles.indication}
                loading={indications.isLoading}
                listItems={indications.list}
                placeholder='Indication'
                legend='Indication'
                size='large'
                onSearch={indications.fetchIndications}
                onSelectCb={async (value) => {
                  await setFieldValue('indicationName', value, false);

                  await validateFieldCb('indicationName');

                  dosage.onSelectIndicationName(value);
                }}
                onCleanCb={dosage.onSelectIndicationName}
                onChange={dosage.onSelectIndicationName}
              />
              <div className={styles.buttonContainer}>
                <AppButton
                  onClick={onCreateEmptySku}
                  className={styles.button1}
                  disabled={values.drugName.length < DRUG_NAME_LENGTH || currentSkus.length === 15}
                  color='secondary'
                  text='Add SKU'
                />
                {!dosingVisible && (
                  <AppButton
                    disabled={isAddDosingButtonDisabled}
                    onClick={addDosing}
                    className={styles.button2}
                    color='secondary'
                    text='Add Dosing'
                    tooltipText='Select country and indication before adding dosing'
                  />
                )}
              </div>
            </div>
            {skusVisible && (
              <SkusList
                errors={errors.skus}
                onValidate={validateForm}
                WebScrapperComponent={
                  <TextButton
                    isLoading={isLoading}
                    tooltipText='Select country before using a web scraper'
                    LoaderComponent={
                      <div className={styles.loaderContainer}>
                        <Loader />
                      </div>
                    }
                    disabled={
                      !dosage.isScrapperAvailable || values.drugName.length <= DRUG_NAME_LENGTH
                    }
                    className={styles.scrapperButton}
                    onClick={startScrapping}
                    text='Automatic web scraper'
                  />
                }
                onRemove={onDeleteSku}
                skus={currentSkus}
              />
            )}
            {dosingVisible && (
              <Dosing
                hideDosing={() => {
                  setDosingVisible(false);
                }}
                onValidate={validateForm}
                errors={errors.phases}
                phases={dosage.phases}
                deletePhase={dosage.deletePhase}
                addNewPhase={dosage.addNewPhase}
                changeActive={dosage.changeActive}
              />
            )}
            <div className={styles.footerContainer}>
              <AppButton
                className={styles.button1}
                onClick={onClickClose}
                color='secondary'
                text='Cancel'
              />
              <AppButton
                className={styles.button2}
                disabled={isLoading || onSubmitLoading}
                loading={onSubmitLoading}
                text={isAddDosingButtonDisabled ? 'Save' : 'Create a new therapy'}
                submit
              />
            </div>
          </form>
        </div>
      ) : (
        <Complete onClose={onClose} />
      )}
    </ModalBase>
  );
};

export default observer(NewDrugModal);
