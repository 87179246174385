import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import cn from 'classnames';

import { observer } from 'mobx-react';

import { AutoComplete } from 'antd';
import Highlighter from 'react-highlight-words';

import { ReactComponent as ArrowIcon } from '../../../assets/img/arrow.svg';
import { ReactComponent as ClearIcon } from '../../../assets/img/clear.svg';
import { ReactComponent as PencilIcon } from '../../../assets/img/pencil.svg';
import { ReactComponent as BasketIcon } from '../../../assets/img/basket.svg';

import styles from './styles.module.scss';

const AppSelect = ({
  size,
  placeholder,
  legend,
  loading,
  onSearch,
  listItems,
  onSelectCb,
  fieldsetClassName,
  defaultValue,
  onCleanCb,
  onChange,
  onEdit,
  onDelete,
  error,
  isSelect,
}) => {
  const [savedSearchValue, setSavedSearchValue] = useState('');
  const [focus, setFocus] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const onFocus = (value) => {
    if (value) {
      setFocus(value);

      setSavedSearchValue(value);
    }

    if (!value) {
      setFocus('');
    }

    if (onSelectCb) {
      onSelectCb(value);
    }
  };

  const handleSearchInputValueChange = useDebouncedCallback((value) => {
    setSavedSearchValue(value);

    if (onSearch) {
      onSearch(value);
    }
  }, 300);

  useEffect(() => {
    if (defaultValue) {
      setSavedSearchValue(defaultValue);
      setFocus(defaultValue);
    }
  }, [defaultValue]);

  return (
    <fieldset className={cn(styles.selectFieldset, fieldsetClassName, error && styles.error)}>
      {focus && <legend className={styles.selectLegend}>{legend}</legend>}
      <AutoComplete
        suffixIcon={<ArrowIcon className={`${isOpen && styles.arrowUp} ${styles.arrow}`} />}
        size={size}
        onChange={(value) => {
          if (onChange) {
            onChange(value, true);
          }

          if (value === '') {
            onSelectCb('');
          }
        }}
        value={defaultValue}
        allowClear
        clearIcon={
          <div className={styles.cleanIcon}>
            <ClearIcon />
          </div>
        }
        className={styles.appSelect}
        onSelect={onFocus}
        notFoundContent={<div>Not found</div>}
        onClear={() => {
          onFocus('');
          handleSearchInputValueChange('');

          if (onCleanCb) {
            onCleanCb(null);
          }
        }}
        bordered={false}
        loading={loading}
        open={isOpen}
        showArrow
        searchValue={undefined}
        onSearch={isSelect ? undefined : handleSearchInputValueChange}
        autoClearSearchValue
        placeholder={placeholder}
        onDropdownVisibleChange={(open) => {
          setIsOpen(open);

          if (open && onSearch) {
            onSearch(savedSearchValue);
          }
        }}
      >
        {listItems.map((item) => {
          return (
            <AutoComplete.Option key={item} value={item}>
              {onSearch ? (
                <div className={styles.textContainer}>
                  <Highlighter
                    highlightStyle={{
                      fontWeight: 700,
                      backgroundColor: 'transparent',
                    }}
                    searchWords={[savedSearchValue]}
                    textToHighlight={item}
                  />
                  {item.length >= 24 && <span className={styles.dots}>...</span>}
                  <div style={{ display: 'flex' }}>
                    {onDelete && (
                      <button
                        type='button'
                        onClick={(e) => {
                          e.stopPropagation();
                          onDelete(item);
                        }}
                        className={styles.editIconContainer}
                      >
                        <BasketIcon />
                      </button>
                    )}
                    {onEdit && (
                      <button
                        type='button'
                        onClick={(e) => {
                          e.stopPropagation();
                          onEdit(item);
                        }}
                        className={styles.editIconContainer}
                      >
                        <PencilIcon />
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                <span>{item}</span>
              )}
            </AutoComplete.Option>
          );
        })}
      </AutoComplete>
    </fieldset>
  );
};

export default observer(AppSelect);
