import React from 'react';
import { Tooltip } from 'antd';

import cn from 'classnames';
import styles from './styles.module.scss';
import Loader from '../../Loader';

const AppButton = ({
  text = '',
  submit = false,
  color = 'primary',
  size = 'large',
  disabled = false,
  onClick,
  className,
  children,
  buttonRef,
  tooltipText,
  loading,
}) => {
  const handleClick = () => {
    if (!disabled && !submit) {
      onClick();
    }
  };

  const node = (
    <button
      ref={buttonRef}
      type={submit ? 'submit' : 'button'}
      className={cn(
        styles.button,
        styles[color],
        styles[size],
        loading && styles.loading,
        className,
      )}
      disabled={disabled}
      onClick={handleClick}
    >
      {loading ? <Loader size={24} color='#fff' /> : children || text}
    </button>
  );

  if (tooltipText) {
    return (
      <Tooltip placement='right' title={tooltipText}>
        <button
          type='button'
          onClick={handleClick}
          className={cn(
            styles.button,
            styles[color],
            styles[size],
            disabled && styles.disabled,
            className,
          )}
        >
          {text}
        </button>
      </Tooltip>
    );
  }

  return node;
};

export default AppButton;
