import React, { useState } from 'react';
import { observer } from 'mobx-react';

import ModalBase from '../ModalBase';
import AppButton from '../../ui/AppButton';

import styles from './styles.module.scss';
import Complete from '../../Complete';

const DeleteTherapyModal = ({ visible, setVisible, onDelete }) => {
  const [step, setStep] = useState(1);

  const onClose = () => {
    setVisible(false);
    setStep(1);
  };

  const onClickDelete = () => {
    onDelete(() => {
      setStep(2);
    });
  };

  return (
    <ModalBase width={404} visible={visible} onClose={onClose}>
      {step === 1 ? (
        <div className={styles.container}>
          <h2>Delete therapy?</h2>
          <p>
            Do you really want to delete this therapy price calculation? This action cannot be
            undone.
          </p>

          <div className={styles.buttons}>
            <AppButton text='Delete' onClick={onClickDelete} />
            <AppButton text='Cancel' onClick={onClose} color='transparent' />
          </div>
        </div>
      ) : (
        <Complete onClose={onClose} text='Therapy has been deleted.' />
      )}
    </ModalBase>
  );
};

export default observer(DeleteTherapyModal);
