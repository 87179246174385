import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';

import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import TableHead from './TableHead';
import { ReactComponent as Download } from '../../assets/img/download.svg';
import { useRootModel } from '../../models/rootStore';
import CommentForm from '../forms/CommentForm';

import ActionBar from '../ActionBar';
import DeleteTherapyModal from '../modals/DeleteTherapyModal';
import ViewAssumptionModal from '../modals/ViewAssumptionModal';
import EditSkuModal from '../modals/EditSkuModal';
import EditIndicationModal from '../modals/EditIndicationModal';

import numberWithCommas from '../../utils/numberWithComas';

import { getUnitRevert } from '../../utils/constans';
import Loader from '../Loader';

const Table = () => {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [viewAssumptionModalVisible, setViewAssumptionModalVisible] = useState(false);
  const [editSkuModalVisible, setEditSkuModalVisible] = useState(false);
  const [editIndicationModalVisible, setEditIndicationModalVisible] = useState(false);
  const [tableHeight, setTableHeight] = useState(0);

  const tableRef = useRef(null);

  const {
    therapies: { selectedTherapyId, setSelectedTherapyId, isLoading, therapies, deleteTherapy },
  } = useRootModel();

  const onClickDelete = (id) => {
    setDeleteModalVisible(true);
    setSelectedTherapyId(id);
  };

  const onClickViewAssumption = (id) => {
    setViewAssumptionModalVisible(true);
    setSelectedTherapyId(id);
  };

  const onClickEditSku = (id) => {
    setEditSkuModalVisible(true);
    setSelectedTherapyId(id);
  };

  const onClickEditIndication = (id) => {
    setEditIndicationModalVisible(true);
    setSelectedTherapyId(id);
  };

  const onDelete = (onSuccessCb) => {
    deleteTherapy(selectedTherapyId, onSuccessCb);
  };

  useEffect(() => {
    setTableHeight(tableRef.current.scrollHeight);
  }, [therapies.length]);

  return (
    <>
      <div className={styles.table} ref={tableRef}>
        <TableHead />
        {isLoading && (
          <div style={{ height: tableHeight }} className={styles.loaderContainer}>
            <Loader tip='Loading...' size={50} />
          </div>
        )}
        {therapies.map((el) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={styles.cols} key={el.id}>
            <div className={styles.col}>{el?.countryName}</div>
            <div className={styles.col}>{el.drugName}</div>
            <div className={styles.col}>
              <div className={styles.indication}>
                {el.dosage?.indicationName && (
                  <>
                    <h4>Indication</h4>
                    <p>{el.dosage?.indicationName}</p>
                  </>
                )}
                {el.dosage.phases && (
                  <div className={styles.dosage}>
                    <h4 className={styles.dosageTitle}>Dosage</h4>
                    <div className={styles.phasesContainer}>
                      {el.dosage.phases.every(
                        (item) =>
                          item.activeIngredientAmount &&
                          item.unit &&
                          item.numberOfTimes &&
                          item.frequency &&
                          item.frequencyDuration &&
                          item.periodNumber &&
                          item.periodDuration,
                      ) &&
                        el.dosage.phases.map((phase) => (
                          <p key={phase.name}>{`Take ${
                            phase.activeIngredientAmount
                          } ${getUnitRevert(phase.unit)} ${phase.numberOfTimes}
                       times per ${phase.frequency} 
                       ${phase.frequencyDuration && phase.frequencyDuration.toLowerCase()} 
                       For ${phase.periodNumber} ${
                            phase.periodDuration && phase.periodDuration.toLowerCase()
                          }`}</p>
                        ))}
                    </div>
                  </div>
                )}
                {!el.dosage.indicationName && !el.dosage && (
                  <p>Indication / dosing were found but not for this country</p>
                )}
                {el.isActiveCommentEditing && (
                  <CommentForm
                    stopCommentEditing={el.stopCommentEditing}
                    valueComment={el.comment ? el.comment : ''}
                    saveComment={el.saveComment}
                    therapyId={el.id}
                  />
                )}
                {el.comment && !el.isActiveCommentEditing && (
                  <>
                    <h4 className={styles.comment}>Comment</h4>
                    <p className={styles.commentText}>{el.comment}</p>
                  </>
                )}
              </div>
            </div>
            <div className={styles.col}>
              {el.currency} {numberWithCommas(el.pricePerMonth)}
            </div>
            <div className={styles.col}>
              {el.currency} {numberWithCommas(el.priceCot)}
            </div>
            <div className={styles.col}>
              <ActionBar
                onClickDelete={() => {
                  onClickDelete(el.id);
                }}
                startEditComment={el.startCommentEditing}
                onClickViewAssumption={() => {
                  onClickViewAssumption(el.id);
                }}
                onClickEditSku={() => {
                  onClickEditSku(el.id);
                }}
                onClickEditIndication={() => {
                  onClickEditIndication(el.id);
                }}
              />
            </div>
            <div className={styles.col}>
              <div className={styles.download}>
                <Download
                  onClick={() => {
                    const { downloadTherapy, id } = el;

                    downloadTherapy(id);
                  }}
                />
              </div>
              <div className={styles.lastDownload}>
                <h4>Last download</h4>
                <div>{el.lastDownload ? moment(el.lastDownload).format('MM/DD/YYYY') : 'N/A'}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <DeleteTherapyModal
        onDelete={onDelete}
        visible={deleteModalVisible}
        setVisible={setDeleteModalVisible}
      />
      <ViewAssumptionModal
        visible={viewAssumptionModalVisible}
        setVisible={setViewAssumptionModalVisible}
      />
      <EditSkuModal visible={editSkuModalVisible} setVisible={setEditSkuModalVisible} />

      <EditIndicationModal
        visible={editIndicationModalVisible}
        setVisible={setEditIndicationModalVisible}
      />
    </>
  );
};

export default observer(Table);
