import React from 'react';

import { observer } from 'mobx-react';

import ModalBase from '../ModalBase';
import { useRootModel } from '../../../models/rootStore';

import styles from './styles.module.scss';
import AppButton from '../../ui/AppButton';

const ViewAssumptionModal = ({ visible, setVisible }) => {
  const {
    therapies: { selectedTherapy },
  } = useRootModel();

  const onClose = () => {
    setVisible(false);
  };

  return (
    selectedTherapy && (
      <ModalBase width={396} visible={visible} onClose={onClose}>
        <div className={styles.container}>
          <div className={styles.header}>
            <h2>
              {`${selectedTherapy.countryName} - ${selectedTherapy.drugName} - 
              ${selectedTherapy?.dosage?.indicationName}`}
            </h2>
          </div>
          {selectedTherapy.timeAssumptions && (
            <div className={styles.listContainer}>
              <h3>Time Assumptions (one month)</h3>
              <div className={styles.list}>
                <div className={styles.row}>
                  <span>Days</span>
                  <span>{selectedTherapy.timeAssumptions.days}</span>
                </div>
                <div className={styles.row}>
                  <span>Weeks</span>
                  <span>{selectedTherapy.timeAssumptions.weeks}</span>
                </div>
                <div className={styles.row}>
                  <span>Months</span>
                  <span>{selectedTherapy.timeAssumptions.months}</span>
                </div>
                <div className={styles.row}>
                  <span>Years</span>
                  <span>{selectedTherapy.timeAssumptions.years}</span>
                </div>
                <div className={styles.row}>
                  <span>Hours</span>
                  <span>{selectedTherapy.timeAssumptions.hours}</span>
                </div>
              </div>
            </div>
          )}
          {selectedTherapy.patientAssumptions && (
            <div className={styles.listContainer}>
              <h3>Patient Assumptions</h3>

              <div className={styles.list}>
                <div className={styles.row}>
                  <span>Country</span>
                  <span>{selectedTherapy.patientAssumptions.country}</span>
                </div>
                <div className={styles.row}>
                  <span>Weight (kg)</span>
                  <span>{selectedTherapy.patientAssumptions.weight}</span>
                </div>
                <div className={styles.row}>
                  <span>Body surface area (m^2)</span>
                  <span>{selectedTherapy.patientAssumptions.area}</span>
                </div>
              </div>
            </div>
          )}
          <p className={styles.bottomText}>
            Assumptions can not be changed within the pricing tool. If you want to calculate with
            different assumption, use the download function into an excel file where assumptions can
            be changed and updated calculations can be seen.
          </p>

          <AppButton text='Close' onClick={onClose} color='secondary' className={styles.button} />
        </div>
      </ModalBase>
    )
  );
};

export default observer(ViewAssumptionModal);
