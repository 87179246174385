import React, { useState } from 'react';
import cn from 'classnames';

import { observer } from 'mobx-react';
import styles from './styles.module.scss';

import { ReactComponent as AddPhaseIcon } from '../../assets/img/plus.svg';
import { ReactComponent as RemovePhaseIcon } from '../../assets/img/remove.svg';
import { ReactComponent as PinkCancel } from '../../assets/img/pink-cancel.svg';

import { PERIODS, PHASE_UNITS, getUnitRevert } from '../../utils/constans';

import TextButton from '../buttons/TextButton';
import InputBase from '../InputBase';

import { checkIsNumber } from '../../utils/checkIsNumber';
import AppSelect from '../ui/AppSelect';

const Phases = ({
  phases,
  addNewPhase,
  onChangeActive,
  deletePhase,
  errors,
  onValidate,
  hideDosing,
}) => {
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);

  const activePhase = phases.find((item) => item.active);

  const onClickDelete = () => {
    if (activePhase) {
      setConfirmDeleteVisible(true);
    }
  };

  const cancelDelete = () => {
    setConfirmDeleteVisible(false);
  };

  const onSelectPhase = (phase) => {
    onChangeActive(phase);

    phase.setActive();

    cancelDelete();
  };

  const onDelete = () => {
    if (activePhase) {
      deletePhase(activePhase.name);
      cancelDelete();
      onValidate();

      if (phases.length === 0 && hideDosing) {
        hideDosing();
      }
    }
  };

  const getError = (index, fieldName) => {
    if (errors && typeof errors !== 'string') {
      const row = errors[index];

      if (row !== undefined) {
        if (fieldName in row) {
          return true;
        }
      }
    }

    return false;
  };

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        {phases.map((phase, index) => (
          <button
            type='button'
            onClick={() => {
              onSelectPhase(phase);
            }}
            className={cn(
              styles.phase,
              phase.active && styles.selected,
              errors && errors[index] !== undefined && styles.errorPhaseTab,
            )}
            key={phase.name}
          >
            {phase.name}
          </button>
        ))}
        <button onClick={addNewPhase} type='button' className={styles.round}>
          <AddPhaseIcon />
        </button>
        <button onClick={onClickDelete} type='button' className={styles.round}>
          <RemovePhaseIcon />
        </button>

        {confirmDeleteVisible && (
          <div className={styles.confirmContainer}>
            <button
              onClick={cancelDelete}
              type='button'
              className={cn(styles.round, styles.roundConfirmCancel)}
            >
              <PinkCancel />
            </button>
            <div>{`Are you sure you want to delete ${activePhase.name}?`}</div>
            <TextButton onClick={onDelete} className={styles.confirmButton} text='Confirm' />
          </div>
        )}
      </div>

      {phases.map(
        (item, index) =>
          item.active && (
            <div key={item.name} className={styles.phases}>
              <InputBase
                error={getError(index, 'activeIngredientAmount')}
                topLabel='Take'
                className={styles.phaseInput}
                placeholder='Active ingredient amount'
                type='text'
                legend='Active ingredient amount'
                value={item.activeIngredientAmount ? item.activeIngredientAmount : ''}
                onChange={(e) => {
                  const { value } = e.target;

                  const isNumber = checkIsNumber(value);

                  if (isNumber && value < 999999) {
                    item.onChangeActiveAmount(value);

                    if (getError(index, 'activeIngredientAmount')) {
                      onValidate();
                    }
                  }
                }}
              />

              <AppSelect
                error={getError(index, 'unit')}
                defaultValue={item.unit ? getUnitRevert(item.unit) : undefined}
                fieldsetClassName={styles.phaseSelect2}
                placeholder='Units'
                legend='Units'
                listItems={PHASE_UNITS}
                size='large'
                isSelect
                onSelectCb={(unit) => {
                  item.onChangeUnit(unit);

                  if (getError(index, 'unit')) {
                    onValidate();
                  }
                }}
              />

              <InputBase
                error={getError(index, 'numberOfTimes')}
                topLabel='Frequency'
                className={styles.phaseInput}
                placeholder='Number of times taken'
                type='text'
                legend='Number of times taken'
                value={item.numberOfTimes ? item.numberOfTimes : ''}
                onChange={(e) => {
                  const { value } = e.target;

                  const isNumber = checkIsNumber(value);

                  if (isNumber && value < 999999) {
                    item.onChangeNumberOfTimes(+value);

                    if (getError(index, 'numberOfTimes')) {
                      onValidate();
                    }
                  }
                }}
              />

              <span className={styles.inlineText}>times, every</span>

              <InputBase
                error={getError(index, 'frequency')}
                className={styles.phaseInput2}
                placeholder='Frequency'
                type='text'
                legend='Frequency'
                value={item.frequency ? item.frequency : ''}
                onChange={(e) => {
                  const { value } = e.target;

                  const isNumber = checkIsNumber(value);

                  if (isNumber && value < 999999) {
                    item.onChangeFrequency(+value);

                    if (getError(index, 'frequency')) {
                      onValidate();
                    }
                  }
                }}
              />

              <AppSelect
                error={getError(index, 'frequencyDuration')}
                defaultValue={item.frequencyDuration ? item.frequencyDuration : undefined}
                fieldsetClassName={styles.phaseSelect2}
                placeholder='Duration'
                isSelect
                legend='Duration'
                listItems={PERIODS}
                size='large'
                onSelectCb={(value) => {
                  item.onChangeFrequencyDuration(value);

                  if (getError(index, 'frequencyDuration')) {
                    onValidate();
                  }
                }}
              />

              <span className={styles.inlineText}>for</span>

              <InputBase
                error={getError(index, 'periodNumber')}
                topLabel='Period'
                className={styles.phaseInput2}
                placeholder='Period'
                type='text'
                legend='Period'
                value={item.periodNumber ? item.periodNumber : ''}
                onChange={(e) => {
                  const { value } = e.target;

                  const isNumber = checkIsNumber(value);

                  if (isNumber && value < 999999) {
                    item.onChangePeriodNumber(+value);

                    if (getError(index, 'periodNumber')) {
                      onValidate();
                    }
                  }
                }}
              />

              <AppSelect
                error={getError(index, 'periodDuration')}
                defaultValue={item.periodDuration ? item.periodDuration : undefined}
                fieldsetClassName={styles.phaseSelect2}
                placeholder='Duration'
                legend='Duration'
                listItems={PERIODS}
                isSelect
                size='large'
                onSelectCb={(value) => {
                  item.onChangePeriodDuration(value);

                  if (getError(index, 'periodDuration')) {
                    onValidate();
                  }
                }}
              />
            </div>
          ),
      )}
    </div>
  );
};

export default observer(Phases);
