import { types } from 'mobx-state-tree';

const SortModel = types.model('SortModel', {
  empty: types.maybeNull(types.boolean),
  sorted: types.maybeNull(types.boolean),
  unsorted: types.maybeNull(types.boolean),
});

const PaginationModel = types.model('PaginationModel', {
  empty: types.maybeNull(types.boolean),
  first: types.maybeNull(types.boolean),
  last: types.maybeNull(types.boolean),
  pageNumber: types.optional(types.number, 0),
  size: types.optional(types.number, 5),
  sort: types.maybeNull(SortModel),
  totalElements: types.maybeNull(types.number),
  totalPages: types.maybeNull(types.number),
  itemsOnPage: types.optional(types.number, 5),
});

export default PaginationModel;
