export const SORT_PARAMS = {
  COUNTRY_NAME: 'countryName',
  INDICATION_AND_DOSING: 'indicationAndDosing',
  DRUG_NAME: 'drugName',
  PRICE_PER_MONTH: 'pricePerMonth',
  PRICE_COT: 'priceCot',
  LAST_DOWNLOADS: 'lastDownload',
};

export const STRENGTH_UNITS = ['mg', 'ml', 'mg/ml'];

export const SKUS_TYPES = ['TABLET', 'INJECTION', 'INHALANT', 'TOPICAL'];

export const PERIODS = ['DAYS', 'WEEKS', 'MONTHS', 'YEARS'];

export const PHASE_UNITS = ['mg', 'ml', 'mg/kg', 'ml/kg', 'mg/m^2', 'ml/m^2'];

export const getUnit = (unitAbbr) => {
  switch (unitAbbr) {
    case 'mg':
      return 'MG';
    case 'ml':
      return 'ML';
    case 'mg/kg':
      return 'MG_PER_KG';
    case 'ml/kg':
      return 'ML_PER_KG';
    case 'mg/m^2':
      return 'MG_PER_SQUARE_METER';
    case 'ml/m^2':
      return 'ML_PER_SQUARE_METER';
    default:
      return unitAbbr;
  }
};

export const getUnitRevert = (unit) => {
  switch (unit) {
    case 'MG':
      return 'mg';
    case 'ML':
      return 'ml';
    case 'MG_PER_KG':
      return 'mg/kg';
    case 'ML_PER_KG':
      return 'ml/kg';
    case 'MG_PER_SQUARE_METER':
      return 'mg/m^2';
    case 'ML_PER_SQUARE_METER':
      return 'ml/m^2';
    default:
      return unit;
  }
};

// Strength unit

export const getStrengthUnit = (strengthUnit) => {
  switch (strengthUnit) {
    case 'mg':
      return 'MG';
    case 'ml':
      return 'ML';
    case 'mg/ml':
      return 'MG_PER_ML';
    default:
      return strengthUnit;
  }
};

export const getStrengthUnitRevert = (strengthUnit) => {
  switch (strengthUnit) {
    case 'MG':
      return 'mg';
    case 'ML':
      return 'ml';
    case 'MG_PER_ML':
      return 'mg/ml';
    default:
      return strengthUnit;
  }
};
