import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useFormik } from 'formik';

import * as Yup from 'yup';
import { useRootModel } from '../../../models/rootStore';
import ModalBase from '../ModalBase';
import styles from '../NewDrugModal/styles.module.scss';
import InputBase from '../../InputBase';
import AppButton from '../../ui/AppButton';
import SkusList from '../../SkusList';
import TextButton from '../../buttons/TextButton';
import Loader from '../../Loader';
import Dosing from '../../Dosing';
import Complete from '../../Complete';
import ConfirmCloseModal from '../ConfirmCloseModal';

const NewTherapyModal = ({ visible, setVisible }) => {
  const [step, setStep] = useState(1);
  const [skusVisible, setSkusVisible] = useState(false);

  const validationSchema = useMemo(() => {
    return Yup.object({
      skus: Yup.array().of(
        Yup.object().shape({
          // currency: Yup.string().required(),
          other: Yup.string().nullable(),
          packageUnit: Yup.number().min(0.1).required(),
          pricePerUnit: Yup.string().min(0.1).required(),
          strength: Yup.number().min(0.1).required(),
          strengthUnit: Yup.string().required(),
          todaysDate: Yup.string().required(),
          type: Yup.string().required(),
          unit: Yup.number().min(0.1).required(),
        }),
      ),
      phases: Yup.array().of(
        Yup.object().shape({
          activeIngredientAmount: Yup.number().min(0.1).required(),
          frequency: Yup.number().min(0.1).required(),
          frequencyDuration: Yup.string().required(),
          numberOfTimes: Yup.number().min(0.1).required(),
          periodDuration: Yup.string().required(),
          periodNumber: Yup.number().min(0.1).required(),
          unit: Yup.string().required(),
        }),
      ),
    });
  }, [skusVisible]);

  const {
    drugCreating: {
      isLoading,
      onSubmitLoading,
      cleanErrors,
      errorMessage,
      createEmptySku,
      currentSkus,
      cleanSkus,
      currentDrug: { dosage },
      startScrappingForNotSaved,
      deleteSku,
      createTherapy,
      getSkusForNewTherapyByDrugName,
    },
    therapies: { fetchTherapies },
    filters: { indications, countries, drugs },
    app: { showConfirmCloseModal },
  } = useRootModel();

  const {
    handleSubmit,
    values,
    handleChange,
    errors,
    setErrors,
    setFieldValue,
    validateForm,
    resetForm,
  } = useFormik({
    initialValues: {
      skus: currentSkus,
      phases: dosage.phases,
    },
    validationSchema,
    onSubmit: async (formikValues) => {
      try {
        await validateForm();

        createTherapy(formikValues.drugName, () => {
          setStep(4);
        });
      } catch (err) {
        // console.log(err);
      }
    },
  });

  const onDeleteSku = (id) => {
    deleteSku(id);

    validateForm();
  };

  const onClose = () => {
    if (errorMessage) {
      cleanErrors();
    }
    setErrors({});

    setStep(1);
    cleanSkus();
    setSkusVisible(false);
    dosage.cleanFields();
    resetForm({
      drugName: null,
      skus: [],
      indicationName: null,
      countryName: null,
      phases: [],
    });

    setVisible(false);
    fetchTherapies();
  };

  const onCreateEmptySku = () => {
    createEmptySku(() => {
      if (step !== 2) {
        setSkusVisible(true);
      }
    });
  };

  const modalWidth = useMemo(() => {
    switch (step) {
      case 4:
        return 404;
      default:
        return 1281;
    }
  }, [step]);

  const startScrapping = () => {
    startScrappingForNotSaved();
  };

  const onClickClose = () => {
    showConfirmCloseModal();
  };

  useEffect(() => {
    if (errorMessage) {
      cleanErrors();
    }
  }, [values]);

  useEffect(() => {
    if (drugs.selectedDrug) {
      setFieldValue('drugName', drugs.selectedDrug);
      dosage.onSelectDrugName(drugs.selectedDrug);
    }

    if (indications.selectedIndication) {
      setFieldValue('indicationName', indications.selectedIndication);
      dosage.onSelectIndicationName(indications.selectedIndication);
    }

    if (countries.selectedCountry) {
      setFieldValue('countryName', countries.selectedCountry);
      dosage.onSelectCountryName(countries.selectedCountry);
    }
  }, [visible, drugs.selectedDrug, indications.selectedIndication, countries.selectedCountry]);

  useEffect(() => {
    if (errorMessage) {
      setErrors({ drugName: errorMessage });
    }

    if (!errorMessage) {
      setErrors({});
    }
  }, [errorMessage]);

  useEffect(() => {
    if (visible) {
      dosage.addNewPhase({ needActive: true });
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      getSkusForNewTherapyByDrugName();
    }
  }, [visible]);

  return (
    <ModalBase
      showCloseIcon={step !== 4}
      width={modalWidth}
      visible={visible}
      onClose={onClickClose}
    >
      <ConfirmCloseModal onClose={onClose} />

      {step === 1 || step === 2 || step === 3 ? (
        <div className={styles.container}>
          <h2>New therapy for existing drug</h2>
          <form onSubmit={handleSubmit}>
            <div className={styles.headerContainer}>
              <InputBase
                readOnly
                className={styles.inputHeader}
                placeholder='Drug name'
                legend='Drug name'
                name='drugName'
                type='text'
                handleChange={handleChange}
                onChange={(e) => {
                  handleChange(e);

                  dosage.onSelectDrugName(e.target.value);
                }}
                value={values.drugName}
              />

              <InputBase
                readOnly
                className={styles.country}
                placeholder='Country name'
                legend='Country'
                type='text'
                value={dosage.countryName}
              />

              <InputBase
                readOnly
                className={styles.inputHeader}
                placeholder='Indication name'
                legend='Indication'
                type='text'
                value={dosage.indicationName}
              />

              <div className={styles.buttonContainer}>
                <AppButton
                  onClick={onCreateEmptySku}
                  className={styles.button1}
                  disabled={currentSkus.length === 15}
                  color='secondary'
                  text='Add SKU'
                />
              </div>
            </div>
            <SkusList
              errors={errors.skus}
              onValidate={validateForm}
              WebScrapperComponent={
                <TextButton
                  isLoading={isLoading}
                  tooltipText='Select country before using a web scraper'
                  LoaderComponent={
                    <div className={styles.loaderContainer}>
                      <Loader />
                    </div>
                  }
                  className={styles.scrapperButton}
                  onClick={startScrapping}
                  text='Automatic web scraper'
                />
              }
              onRemove={onDeleteSku}
              skus={currentSkus}
            />
            <Dosing
              onValidate={validateForm}
              errors={errors.phases}
              phases={dosage.phases}
              deletePhase={dosage.deletePhase}
              addNewPhase={dosage.addNewPhase}
              changeActive={dosage.changeActive}
            />
            <div className={styles.footerContainer}>
              <AppButton
                className={styles.button1}
                onClick={onClickClose}
                color='secondary'
                text='Cancel'
              />
              <AppButton
                className={styles.button2}
                disabled={isLoading || onSubmitLoading}
                loading={onSubmitLoading}
                text='Create a new therapy'
                submit
              />
            </div>
          </form>
        </div>
      ) : (
        <Complete onClose={onClose} text='Therapy has been successfully created.' />
      )}
    </ModalBase>
  );
};

export default observer(NewTherapyModal);
