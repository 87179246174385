import React from 'react';
import { ReactComponent as Logo } from '../../assets/img/logo.svg';
import styles from './styles.module.scss';
import Logout from '../Logout';

const Header = () => {
  return (
    <div className={styles.header}>
      <span className={styles.text}>Home</span>
      <div className={styles.rightContainer}>
        <Logo />
        <Logout />
      </div>
    </div>
  );
};

export default Header;
