import React from 'react';
import { observer } from 'mobx-react';
import styles from '../SkusList/styles.module.scss';
import InputBase from '../InputBase';
import { checkIsNumber } from '../../utils/checkIsNumber';
import AppSelect from '../ui/AppSelect';
import { getStrengthUnitRevert, SKUS_TYPES, STRENGTH_UNITS } from '../../utils/constans';
import DatePicker from '../DatePicker';
import TextButton from '../buttons/TextButton';
import { ReactComponent as BasketIcon } from '../../assets/img/basket.svg';

const SkuItem = ({ onValidate, sku, getError, index, onRemove }) => {
  return (
    <div className={styles.item} key={sku.id}>
      <div className={styles.row}>
        <InputBase
          error={getError(index, 'unit')}
          className={styles.select}
          placeholder='Units/SKU'
          type='text'
          legend='Units/SKU'
          value={sku.unit ? sku.unit : ''}
          onChange={(e) => {
            const { value } = e.target;

            const isNumber = checkIsNumber(value);

            if (isNumber && value < 999999) {
              sku.onChangeUnit(+value);

              if (getError(index, 'unit')) {
                onValidate();
              }
            }
          }}
        />
        <InputBase
          error={getError(index, 'strength')}
          className={styles.input}
          placeholder='Strength'
          type='text'
          legend='Strength'
          value={sku.strength ? sku.strength : ''}
          onChange={(e) => {
            const { value } = e.target;

            const isNumber = checkIsNumber(value);

            if (isNumber && value < 999999) {
              sku.onChangeStrength(+value);

              if (getError(index, 'strength')) {
                onValidate();
              }
            }
          }}
        />

        <AppSelect
          error={getError(index, 'strengthUnit')}
          defaultValue={sku.strengthUnit ? getStrengthUnitRevert(sku.strengthUnit) : undefined}
          fieldsetClassName={styles.select}
          placeholder='Strength unit'
          legend='Strength unit'
          listItems={STRENGTH_UNITS}
          size='large'
          isSelect
          onSelectCb={(value) => {
            sku.onChangeStrengthUnit(value);

            if (getError(index, 'strengthUnit')) {
              onValidate();
            }
          }}
        />
        <AppSelect
          error={getError(index, 'type')}
          defaultValue={sku.type ? sku.type : undefined}
          fieldsetClassName={styles.select}
          placeholder='Type'
          legend='Type'
          listItems={SKUS_TYPES}
          size='large'
          isSelect
          onSelectCb={(value) => {
            sku.onChangeType(value);

            if (getError(index, 'type')) {
              onValidate();
            }
          }}
        />
      </div>

      <div className={styles.row}>
        <InputBase
          error={getError(index, 'packageUnit')}
          className={styles.inputPackageUnits}
          placeholder='Package units'
          type='text'
          legend='Package units'
          value={sku.packageUnit ? sku.packageUnit : ''}
          onChange={(e) => {
            const { value } = e.target;

            const isNumber = checkIsNumber(value);

            if (isNumber && value < 999999) {
              sku.onChangePackageUnit(+value);

              if (getError(index, 'packageUnit')) {
                onValidate();
              }
            }
          }}
        />

        <InputBase
          error={getError(index, 'pricePerUnit')}
          className={styles.input}
          placeholder='Price/unit'
          type='text'
          legend='Price/unit'
          showCurrency
          value={sku.pricePerUnit ? sku.pricePerUnit : ''}
          onChange={(e) => {
            const { value } = e.target;

            const isNumber = checkIsNumber(value);

            if (isNumber && value < 999999) {
              sku.onChangePricePerUnit(value);

              if (getError(index, 'pricePerUnit')) {
                onValidate();
              }
            }
          }}
        />

        <InputBase
          placeholder='Currency'
          type='text'
          legend='Currency'
          value={sku.currency ? sku.currency : ''}
          readOnly
          className={styles.input}
        />

        <DatePicker
          onSelect={(date) => {
            sku.onChangeTodaysDate(date);
          }}
          date={sku.todaysDate}
        />

        <InputBase
          className={styles.inputOther}
          placeholder='Other'
          type='Other'
          legend='Other'
          value={sku.other ? sku.other : ''}
          onChange={(e) => {
            const { value } = e.target;

            sku.onChangeOther(value);
          }}
        />
      </div>

      <div className={styles.deleteButtonContainer}>
        <TextButton
          type='button'
          onClick={() => {
            onRemove(sku.id);
          }}
        >
          <BasketIcon />
          <span>Delete sku</span>
        </TextButton>
      </div>
    </div>
  );
};

export default observer(SkuItem);
