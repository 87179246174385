import React from 'react';

import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import Phases from '../Phases';

const Dosing = ({
  changeActive,
  phases,
  addNewPhase,
  deletePhase,
  errors,
  onValidate,
  hideDosing,
}) => {
  return (
    <div className={styles.container}>
      <h3>Dosing</h3>

      <Phases
        hideDosing={hideDosing}
        errors={errors}
        onChangeActive={changeActive}
        phases={phases}
        addNewPhase={addNewPhase}
        deletePhase={deletePhase}
        onValidate={onValidate}
      />
    </div>
  );
};

export default observer(Dosing);
