import { types as t } from 'mobx-state-tree';

const SkusModel = t
  .model('SkusModel', {
    id: t.maybeNull(t.number),
    countryName: t.maybeNull(t.string),
    unit: t.maybeNull(t.number),
    strength: t.maybeNull(t.number),
    strengthUnit: t.maybeNull(t.string),
    type: t.maybeNull(t.string),
    packageUnit: t.maybeNull(t.number),
    pricePerUnit: t.maybeNull(t.union(t.string, t.number)),
    todaysDate: t.maybeNull(t.string),
    other: t.maybeNull(t.string),
    drugName: t.maybeNull(t.string),
    currency: t.maybeNull(t.string),
  })
  .actions((self) => ({
    onChangeCountry(countryName) {
      self.countryName = countryName;
    },
    onChangeUnit(unit) {
      self.unit = unit;
    },
    onChangeStrength(strength) {
      self.strength = strength;
    },
    onChangeStrengthUnit(strengthUnit) {
      self.strengthUnit = strengthUnit;
    },
    onChangeType(type) {
      self.type = type;
    },
    onChangePackageUnit(packageUnit) {
      self.packageUnit = packageUnit;
    },
    onChangePricePerUnit(pricePerUnit) {
      self.pricePerUnit = pricePerUnit;
    },
    onChangeTodaysDate(todaysDate) {
      self.todaysDate = todaysDate;
    },
    onChangeOther(other) {
      self.other = other;
    },
  }));

export default SkusModel;
