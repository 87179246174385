import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

const InputBase = ({
  placeholder,
  id,
  name,
  type,
  onChange,
  value,
  legend,
  error,
  className,
  readOnly,
  autoFocus,
  topLabel,
  currency,
  showCurrency,
}) => {
  const [focus, setFocus] = useState(false);

  const inputRef = useRef();

  const handleChange = (e) => {
    if (readOnly) {
      return;
    }

    onChange(e);
  };

  useEffect(() => {
    if (value && value.toString().length > 0) {
      setFocus(true);
    }

    if (value && value.toString().length === 0) {
      setFocus(false);
    }
  }, [value]);

  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <fieldset className={cn(styles.fieldset, className)}>
      {topLabel && <span className={styles.topLabel}>{topLabel}</span>}
      {focus && <legend className={styles.legend}>{legend}</legend>}
      <input
        autoComplete='off'
        ref={inputRef}
        placeholder={placeholder}
        id={id}
        name={name}
        type={type}
        onChange={handleChange}
        value={value}
        className={cn(styles.input, error && styles.errorInput, readOnly && styles.readOnly)}
      />

      {showCurrency && <div className={styles.rightIcon}>{currency}</div>}
      {error && <div className={styles.error}>{error}</div>}
    </fieldset>
  );
};

export default InputBase;
