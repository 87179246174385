import React, { useMemo } from 'react';

import cn from 'classnames';

import { observer } from 'mobx-react';

import { ReactComponent as SorAlphaAsk } from '../../../assets/img/sort-alpha-asc.svg';
import { ReactComponent as SortAlphaDesc } from '../../../assets/img/sort-alpha-desc.svg';
import { ReactComponent as SortNumericAsc } from '../../../assets/img/sort-numeric-asc.svg';
import { ReactComponent as SortNumericDesc } from '../../../assets/img/sort-numeric-desc.svg';

import styles from './styles.module.scss';
import { useRootModel } from '../../../models/rootStore';

const SortButton = ({ onClick, numeric, name }) => {
  const {
    therapies: { sortBy, sortOrder },
  } = useRootModel();

  const onButtonClick = () => {
    onClick();
  };

  const renderIcon = useMemo(() => {
    if (numeric) {
      return sortOrder === 'asc' ? <SortNumericAsc /> : <SortNumericDesc />;
    }

    return sortOrder === 'asc' ? <SorAlphaAsk /> : <SortAlphaDesc />;
  }, [sortOrder, numeric]);

  return (
    <button
      className={cn(styles.button, name === sortBy && styles.active)}
      type='button'
      onClick={onButtonClick}
    >
      {renderIcon}
    </button>
  );
};

export default observer(SortButton);
