import React, { useState } from 'react';
import { observer } from 'mobx-react';
import ModalBase from '../ModalBase';
import styles from '../DeleteTherapyModal/styles.module.scss';
import AppButton from '../../ui/AppButton';
import Complete from '../../Complete';

const DeleteFilterItemModal = ({
  visible,
  setVisible,
  onDelete,
  completeText,
  savedName,
  saveName,
  subTitle,
}) => {
  const [step, setStep] = useState(1);

  const onClose = () => {
    setVisible(false);
    saveName('');
    setStep(1);
  };

  const onClickDelete = () => {
    onDelete(() => {
      setStep(2);
    });
  };

  return (
    <ModalBase width={404} visible={visible} onClose={onClose}>
      {step === 1 ? (
        <div className={styles.container}>
          <h2>Delete {savedName}?</h2>
          <p>{subTitle}</p>

          <div className={styles.buttons}>
            <AppButton text='Delete' onClick={onClickDelete} />
            <AppButton text='Cancel' onClick={onClose} color='transparent' />
          </div>
        </div>
      ) : (
        <Complete onClose={onClose} text={completeText} />
      )}
    </ModalBase>
  );
};

export default observer(DeleteFilterItemModal);
