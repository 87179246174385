import React from 'react';
import { observer } from 'mobx-react';
import ModalBase from '../ModalBase';
import { useRootModel } from '../../../models/rootStore';
import AppButton from '../../ui/AppButton';

import styles from './styles.module.scss';

const ConfirmCloseModal = ({ onClose }) => {
  const {
    app: { isConfirmCloseModalVisible, closeConfirmCloseModal },
  } = useRootModel();

  const onClickClose = () => {
    closeConfirmCloseModal();
    onClose();
  };

  return (
    <ModalBase visible={isConfirmCloseModalVisible} width={360} onClose={closeConfirmCloseModal}>
      <div className={styles.container}>
        <h3>Are you sure?</h3>
        <p>If you click confirm, your changes will lost.</p>

        <div className={styles.buttonsContainer}>
          <AppButton onClick={closeConfirmCloseModal} text='Cancel' />
          <AppButton onClick={onClickClose} text='Confirm' />
        </div>
      </div>
    </ModalBase>
  );
};

export default observer(ConfirmCloseModal);
