import { flow, types as t, getRoot } from 'mobx-state-tree';
import axios from 'axios';

const CountryFilterModel = t
  .model('CountryFilterModel', {
    list: t.optional(t.array(t.string), []),
    isLoading: t.optional(t.boolean, false),
    selectedCountry: t.optional(t.string, ''),
  })
  .actions((self) => ({
    fetchCountries: flow(function* fetchCountries(countryName) {
      try {
        self.isLoading = true;

        const params = {
          countryName,
        };

        const { data } = yield axios.get('/countries/v1/matching', { params });

        self.list = data;
      } catch (error) {
        //
      } finally {
        self.isLoading = false;
      }
    }),
    onSelectCountry(countryName) {
      self.selectedCountry = countryName;

      const {
        therapies: { fetchTherapies, changePage },
        drugCreating: {
          currentDrug: {
            dosage: { getCurrency },
          },
        },
      } = getRoot(self);

      if (countryName.length > 0) {
        getCurrency(countryName);
      }

      changePage(0);
      fetchTherapies();
    },
  }));

export default CountryFilterModel;
