import { flow, getRoot, types as t } from 'mobx-state-tree';
import axios from 'axios';
import { toast } from 'react-toastify';

import CurrentDrug from './CurrentDrug';
import SkusModel from '../common/SkusModel';
import { getStrengthUnit, getUnit } from '../../utils/constans';

const DrugCreatingModel = t
  .model('DrugCreatingModel', {
    currentDrug: t.optional(CurrentDrug, {}),
    currentSkus: t.optional(t.array(SkusModel), []),
    isLoading: t.optional(t.boolean, false),
    onSubmitLoading: t.optional(t.boolean, false),
    errorMessage: t.optional(t.string, ''),
  })
  .actions((self) => ({
    getSkusForNewTherapyByDrugName: flow(function* getSkusForNewTherapyByDrugName() {
      const {
        filters: {
          drugs: { selectedDrug },
          countries: { selectedCountry },
        },
      } = getRoot(self);

      try {
        const params = {
          drugName: selectedDrug,
          countryName: selectedCountry,
        };

        self.isLoading = true;

        const { data } = yield axios.get('/skus/v1/for-drug', { params });

        self.currentSkus.push(...data);
      } catch (error) {
        //
      } finally {
        self.isLoading = false;
      }
    }),
    createDrug: flow(function* createDrug(drugName, onSuccessCb) {
      try {
        const config = {
          name: drugName,
        };

        self.onSubmitLoading = true;

        yield axios.post('/drugs/v1', config);

        onSuccessCb();
      } catch (error) {
        self.errorMessage = error?.response?.data?.detail || error.message || 'Server error';
      } finally {
        self.onSubmitLoading = false;
      }
    }),
    createDrugWithParams: flow(function* createDrugWithParams(
      drugName,
      onSuccessCb,
      countryName,
      indicationName,
    ) {
      const {
        currentSkus,
        currentDrug: { dosage },
      } = self;

      const config = {
        skus: currentSkus.map((sku) => ({
          ...sku,
          strengthUnit: getStrengthUnit(sku.strengthUnit),
        })),
        name: drugName,
        dosage: {
          ...dosage,
          phases: dosage.phases.map((phase) => ({
            ...phase,
            unit: getUnit(phase.unit),
          })),
          drugName,
          indicationName: indicationName || dosage.indicationName,
          countryName: countryName || dosage.countryName,
        },
      };

      try {
        self.onSubmitLoading = true;

        yield axios.post('drugs/v1/with-params', config);

        onSuccessCb();
      } catch (error) {
        // console.log(error);
      } finally {
        self.onSubmitLoading = false;
      }
    }),
    createTherapy: flow(function* createTherapy(drugName, onSuccessCb) {
      const {
        currentSkus,
        currentDrug: { dosage },
      } = self;

      const config = {
        skus: currentSkus.map((sku) => ({
          ...sku,
          strengthUnit: getStrengthUnit(sku.strengthUnit),
        })),
        drugName,
        dosage: {
          ...dosage,
          phases: dosage.phases.map((phase) => ({
            ...phase,
            unit: getUnit(phase.unit),
          })),
          drugName,
          indicationName: dosage.indicationName,
          countryName: dosage.countryName,
        },
      };

      try {
        self.onSubmitLoading = true;

        yield axios.post('therapies/v1', config);

        onSuccessCb();
      } catch (error) {
        // console.log(error);
      } finally {
        self.onSubmitLoading = false;
      }
    }),
    createEmptySku: flow(function* createEmptySku(onSuccessCb) {
      const {
        currentSkus,
        currentDrug: {
          dosage: { currency },
        },
      } = self;

      try {
        const { data } = yield axios.post('/skus/v1');

        currentSkus.splice(0, 0, { ...data, currency });

        if (onSuccessCb) {
          onSuccessCb(data);
        }
      } catch (error) {
        self.errorMessage = error?.response?.data?.detail || error.message || 'Server error';
      }
    }),
    changeCurrencyForAllSkus(newCurrency) {
      if (self.currentSkus.length > 0) {
        self.currentSkus = self.currentSkus.map((item) => ({
          ...item,
          currency: newCurrency,
        }));
      }
    },
    deleteSku(skuId) {
      const { currentSkus } = self;

      self.currentSkus = currentSkus.filter((sku) => sku.id !== skuId);
    },
    startScrappingForNotSaved: flow(function* startScrapping() {
      try {
        const {
          currentDrug: {
            dosage: { countryName, drugName, currency },
          },
        } = self;

        const params = {
          countryName,
          drugName,
        };

        self.isLoading = true;

        const { data } = yield axios.get('skus/v1/web-scrapping-for-not-saved', { params });

        self.currentSkus.push(...data.map((item) => ({ ...item, currency })));

        if (data.length === 0) {
          toast.error("Can't find SKUs with this parameters.");
        }
      } catch (error) {
        // console.log(error);
      } finally {
        self.isLoading = false;
      }
    }),
    cleanErrors() {
      self.errorMessage = '';
    },
    cleanSkus() {
      self.currentSkus = [];
    },
  }));

export default DrugCreatingModel;
