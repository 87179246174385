import React from 'react';

import { ToastContainer } from 'react-toastify';

import ReactDOM from 'react-dom/client';
import { rootStore, RootStoreContext } from './models/rootStore';
import './styles/main.scss';
import MainApp from './components/MainApp/MainApp';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RootStoreContext.Provider value={rootStore}>
    <MainApp />
    <ToastContainer
      position='bottom-right'
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </RootStoreContext.Provider>,
);
