const DATE = new Date();
const CURRENT_YEAR = DATE.getFullYear();
const CURRENT_MONTH = DATE.getMonth();
const CURRENT_DAY = DATE.getDate();

export default {
  currentDay: CURRENT_DAY,
  currentMonth: CURRENT_MONTH,
  currentYear: CURRENT_YEAR,
};
