export const checkIsNumber = (str) => {
  const re = /^-?(0|[1-9]\d*)?(\.\d+)?(?<=\d)$/;

  if (str === '') {
    return true;
  }

  if (str.length > 0 && str.slice(-1) === '.') {
    return true;
  }

  return str.match(re);
};
