import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './styles.module.scss';
import InputBase from '../../InputBase';
import AppButton from '../../ui/AppButton';

const NewIndicationForm = ({
  onCreateIndication,
  onEditIndication,
  defaultValue = '',
  onClose,
  setStep,
  errorMessage,
  cleanErrors,
  inputLegend = '',
  placeholder = 'Indication name',
  name = 'indication',
}) => {
  const validationSchema = Yup.object({
    indicationName: Yup.string()
      .min(2, `Name for ${name} should be more than 1 symbol.`)
      .required('Required'),
  });

  const { handleSubmit, handleChange, values, errors, isValid, setErrors, setFieldValue } =
    useFormik({
      initialValues: {
        indicationName: defaultValue,
      },
      validationSchema,
      onSubmit: (formikValues) => {
        if (onEditIndication) {
          onEditIndication(formikValues.indicationName, () => {
            setStep(2);
            setFieldValue('indicationName', '');
          });

          return;
        }

        onCreateIndication(formikValues.indicationName, () => {
          setStep(2);
          setFieldValue('indicationName', '');
        });
      },
    });

  useEffect(() => {
    if (errorMessage) {
      cleanErrors();
    }
  }, [values]);

  useEffect(() => {
    if (errorMessage) {
      setErrors({ indicationName: errorMessage });
    }

    if (!errorMessage) {
      setErrors({});
    }
  }, [errorMessage]);

  useEffect(() => {
    if (defaultValue) {
      setFieldValue('indicationName', defaultValue);
    }
  }, [defaultValue]);

  return (
    <form autoComplete='off' className={styles.form} onSubmit={handleSubmit}>
      <InputBase
        placeholder={placeholder}
        legend={inputLegend}
        id='indicationName'
        name='indicationName'
        type='text'
        onChange={handleChange}
        value={values.indicationName}
        error={errors.indicationName}
      />
      <div className={styles.buttons}>
        <AppButton text='Save' disabled={!isValid || values.indicationName.length === 0} submit />
        <AppButton text='Cancel' onClick={onClose} color='transparent' />
      </div>
    </form>
  );
};

export default observer(NewIndicationForm);
