import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toJS } from 'mobx';

import ModalBase from '../ModalBase';

import styles from './styles.module.scss';
import { useRootModel } from '../../../models/rootStore';
import InputBase from '../../InputBase';
import AppButton from '../../ui/AppButton';
import SkusList from '../../SkusList';
import TextButton from '../../buttons/TextButton';
import Loader from '../../Loader';

import Complete from '../../Complete';
import ConfirmCloseModal from '../ConfirmCloseModal';

const validationSchema = Yup.object({
  skus: Yup.array().of(
    Yup.object().shape({
      // currency: Yup.string().required(),
      other: Yup.string().nullable(),
      packageUnit: Yup.number().min(0.1).required(),
      pricePerUnit: Yup.string().min(0.1).required(),
      strength: Yup.number().min(0.1).required(),
      strengthUnit: Yup.string().required(),
      todaysDate: Yup.string().required(),
      type: Yup.string().required(),
      unit: Yup.number().min(0.1).required(),
    }),
  ),
});

const EditSkuModal = ({ visible, setVisible }) => {
  const [initValues, setInitValues] = useState([]);
  const [step, setStep] = useState(1);
  const {
    therapies: { selectedTherapy, fetchTherapies },
    app: { showConfirmCloseModal },
  } = useRootModel();

  const onSaveSkus = () => {
    const { saveSkus } = selectedTherapy;

    saveSkus(() => {
      setStep(2);
    });
  };

  const { handleSubmit, errors, values, validateForm, setFieldValue, resetForm, setErrors } =
    useFormik({
      initialValues: {
        skus: [],
      },
      validationSchema,
      onSubmit: async () => {
        await validateForm();

        onSaveSkus();
      },
    });

  useEffect(() => {
    if (selectedTherapy) {
      setFieldValue('skus', selectedTherapy.skus);
      setInitValues(toJS(selectedTherapy.skus));
    }
  }, [selectedTherapy]);

  const onClose = () => {
    setVisible(false);

    fetchTherapies();

    setStep(1);

    setStep(1);
    resetForm({
      skus: [],
    });
    setErrors({});
  };

  if (!selectedTherapy) {
    return null;
  }

  const onCreateEmptySku = () => {
    const { createEmptySkuForExistingDrug } = selectedTherapy;

    createEmptySkuForExistingDrug();
  };

  const startScrapping = () => {
    const { startScrappingSkus } = selectedTherapy;

    startScrappingSkus();
  };

  const { isLoading, deleteSku } = selectedTherapy;

  const onDeleteSku = async (id) => {
    deleteSku(id);
    await validateForm();
  };

  const onClickClose = () => {
    const isSame = JSON.stringify(initValues) === JSON.stringify(toJS(values.skus));

    if (isSame) {
      onClose();
    } else {
      showConfirmCloseModal();
    }
  };

  const { onSubmitLoading } = selectedTherapy;

  return (
    <ModalBase width={step === 1 ? 1280 : 404} visible={visible} onClose={onClickClose}>
      <ConfirmCloseModal onClose={onClose} />
      {step === 1 ? (
        <div className={styles.container}>
          <h2>Edit Sku</h2>

          <form onSubmit={handleSubmit}>
            <div className={styles.headerContainer}>
              <InputBase
                className={styles.inputHeader}
                placeholder='Drug name'
                legend='Drug name'
                name='drugName'
                id='drugName'
                type='text'
                value={selectedTherapy.drugName}
                onChange={() => {
                  //
                }}
              />
              <AppButton
                onClick={onCreateEmptySku}
                className={styles.button1}
                color='secondary'
                text='Add SKU'
              />
            </div>

            <SkusList
              onRemove={onDeleteSku}
              errors={errors.skus}
              onValidate={validateForm}
              skus={selectedTherapy.skus}
              WebScrapperComponent={
                <TextButton
                  LoaderComponent={
                    <div className={styles.loaderContainer}>
                      <Loader />
                    </div>
                  }
                  isLoading={isLoading}
                  disabled={!selectedTherapy.webScrappingAllowed}
                  className={styles.scrapperButton}
                  onClick={startScrapping}
                  text='Automatic web scraper'
                />
              }
            />

            <div className={styles.footerContainer}>
              <AppButton
                className={styles.button1}
                onClick={onClickClose}
                color='secondary'
                text='Cancel'
              />
              <AppButton
                disabled={isLoading || onSubmitLoading}
                className={styles.button2}
                submit
                loading={onSubmitLoading}
                text='Save'
              />
            </div>
          </form>
        </div>
      ) : (
        <Complete onClose={onClose} text='Skus has been saved' />
      )}
    </ModalBase>
  );
};

export default observer(EditSkuModal);
