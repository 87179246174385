import React from 'react';
import { observer } from 'mobx-react';

import styles from './styles.module.scss';

import SkuItem from '../SkuItem';

const SkusList = ({ skus, onRemove, WebScrapperComponent, errors, onValidate }) => {
  const onClickRemove = (skuId) => {
    onRemove(skuId);
  };

  const getError = (index, fieldName) => {
    if (errors) {
      const row = errors[index];

      if (row !== undefined) {
        if (fieldName in row) {
          return true;
        }
      }
    }

    return false;
  };

  return (
    <div className={styles.container}>
      <h3>SKUs</h3>
      {WebScrapperComponent}
      <div className={styles.list}>
        {skus.map((sku, index) => (
          <SkuItem
            onValidate={onValidate}
            onRemove={onClickRemove}
            sku={sku}
            index={index}
            getError={getError}
            key={sku.id}
          />
        ))}
      </div>
    </div>
  );
};

export default observer(SkusList);
