import React from 'react';
import cn from 'classnames';
import styles from '../styles.module.scss';
import { useRootModel } from '../../../models/rootStore';
import SortButton from '../../buttons/SortButton';
import { SORT_PARAMS } from '../../../utils/constans';

const TableHead = () => {
  const {
    therapies: { setSortBy },
  } = useRootModel();

  const setSortByCountry = () => {
    setSortBy(SORT_PARAMS.COUNTRY_NAME);
  };

  const setSortByDrug = () => {
    setSortBy(SORT_PARAMS.DRUG_NAME);
  };

  const setSortBYIndication = () => {
    setSortBy(SORT_PARAMS.INDICATION_AND_DOSING);
  };

  const setSortByPricePerMonth = () => {
    setSortBy(SORT_PARAMS.PRICE_PER_MONTH);
  };

  const setSortByPriceCot = () => {
    setSortBy(SORT_PARAMS.PRICE_COT);
  };

  const setSortByLastDownloads = () => {
    setSortBy(SORT_PARAMS.LAST_DOWNLOADS);
  };

  return (
    <div className={styles.tableHeaderContainer}>
      <div className={cn(styles.tableHead, styles.cols)}>
        <div>
          <span>Country</span>
          <SortButton onClick={setSortByCountry} name='countryName' />
        </div>
        <div>
          <span>Drug name</span>
          <SortButton onClick={setSortByDrug} name='drugName' />
        </div>
        <div>
          <span>Indication and dosing</span>
          <SortButton onClick={setSortBYIndication} name='indicationAndDosing' />
        </div>
        <div>
          <span>Price-month</span>
          <SortButton numeric onClick={setSortByPricePerMonth} name='pricePerMonth' />
        </div>
        <div>
          <span>Price-COT</span>
          <SortButton numeric onClick={setSortByPriceCot} name='priceCot' />
        </div>
        <div>
          <span>Actions</span>
        </div>
        <div>
          <span>Downloads</span>
          <SortButton numeric onClick={setSortByLastDownloads} name='lastDownload' />
        </div>
      </div>
    </div>
  );
};

export default TableHead;
