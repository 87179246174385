import React, { useEffect, useLayoutEffect, useState } from 'react';

import animComplete from '../../assets/img/complete.gif';

import styles from './styles.module.scss';

const Complete = ({ onClose, text }) => {
  const [showIcon, setShowIcon] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      onClose();
    }, 1800);

    return () => {
      setShowIcon(false);
    };
  }, []);

  useLayoutEffect(() => {
    setShowIcon(true);
  });

  return (
    <div className={styles.container}>
      <h2>Saved!</h2>
      <p className={styles.text}>
        {text || 'The disease has been added to your list and can now be used.'}
      </p>

      {showIcon && <img alt='complete' src={animComplete} className={styles.icon} />}
    </div>
  );
};

export default Complete;
