import { flow, getRoot, types } from 'mobx-state-tree';
import { toast } from 'react-toastify';

import axios from 'axios';
import SkusModel from '../common/SkusModel';
import PatientAssumptionsModel from '../common/PatientAssumptionsModel';
import TimeAssumptionsModel from '../common/TimeAssumptionsModel';
import DosageModel from '../common/DosageModel';
import { downloadFile } from '../../utils/downloadFile';
import { getStrengthUnit, getUnit } from '../../utils/constans';

const TherapiesModel = types
  .model('TherapiesModel', {
    comment: types.maybeNull(types.string),
    countryName: types.maybeNull(types.string),
    currency: types.optional(types.string, '$'),
    dosage: types.optional(DosageModel, {}),
    drugName: types.maybeNull(types.string),
    id: types.maybeNull(types.number),
    lastDownload: types.maybeNull(types.string),
    priceCot: types.maybeNull(types.number),
    pricePerMonth: types.maybeNull(types.number),
    skus: types.array(SkusModel),
    isLoading: types.optional(types.boolean, false),
    onSubmitLoading: types.optional(types.boolean, false),
    isActiveCommentEditing: types.optional(types.boolean, false),
    patientAssumptions: types.maybeNull(PatientAssumptionsModel),
    timeAssumptions: types.maybeNull(TimeAssumptionsModel),
    errorMessage: types.optional(types.string, ''),
    webScrappingAllowed: types.optional(types.boolean, true),
  })
  .actions((self) => ({
    startCommentEditing() {
      self.isActiveCommentEditing = true;
    },
    stopCommentEditing() {
      self.isActiveCommentEditing = false;
    },
    saveIndicationDrug: flow(function* saveIndicationDrug(onSuccessCb) {
      const {
        dosage: { countryName, indicationName, drugName, phases, id },
      } = self;

      try {
        self.onSubmitLoading = true;

        const config = {
          countryName,
          indicationName,
          drugName,
          id,
          phases: phases.map((phase) => ({
            ...phase,
            unit: getUnit(phase.unit),
          })),
        };

        if (!countryName || !indicationName) {
          toast.warn('Indication name or Country name is missing');
        } else {
          const { data } = yield axios.patch('/dosages/v1', config);

          self.dosage.phases = data.phases;

          onSuccessCb();
        }
      } catch (error) {
        // console.log(error);
      } finally {
        self.onSubmitLoading = false;
      }
    }),
    saveComment: flow(function* saveComment(id, comment, onSuccessCb) {
      const config = {
        id,
        comment,
      };

      try {
        yield axios.patch('/therapies/v1/comment', config);

        onSuccessCb();

        self.comment = comment;
      } catch (error) {
        // console.log(error);
      }
    }),
    createEmptySkuForExistingDrug: flow(function* createEmptySku(onSuccessCb) {
      const { skus, currency } = self;

      try {
        const { data } = yield axios.post('/skus/v1');

        skus.splice(0, 0, { ...data, currency });

        if (onSuccessCb) {
          onSuccessCb();
        }
      } catch (error) {
        self.errorMessage = error?.response?.data?.detail || error.message || 'Server error';
      }
    }),
    deleteSku(skuId) {
      const { skus } = self;

      self.skus = skus.filter((sku) => sku.id !== skuId);
    },
    saveSkus: flow(function* onSaveSkus(onSuccessCb) {
      const { id, skus } = self;

      try {
        self.onSubmitLoading = true;

        const params = {
          id,
        };

        const modifiedSkus = skus.map((sku) => ({
          ...sku,
          strengthUnit: getStrengthUnit(sku.strengthUnit),
        }));

        yield axios.patch('/therapies/v1/add-existing-skus', modifiedSkus, { params });

        onSuccessCb();
      } catch (error) {
        // console.log(error);
      } finally {
        self.onSubmitLoading = false;
      }
    }),
    startScrappingSkus: flow(function* startScrapping() {
      const { id, currency } = self;

      try {
        const params = {
          therapyId: id,
        };

        self.isLoading = true;

        const { data } = yield axios.get('/skus/v1/web-scrapping-therapy', { params });

        self.skus.push(...data.map((item) => ({ ...item, currency })));

        if (data.length === 0) {
          toast.error("Can't find SKUs with this parameters.");
        }
      } catch (error) {
        // console.log(error);
      } finally {
        self.isLoading = false;
      }
    }),
    setFieldsByDefault() {
      const {
        dosage: { countryName, indicationName, oldCountryName, oldIndicationName },
      } = self;

      if (!countryName) {
        self.countryName = oldCountryName;
      }

      if (!indicationName) {
        self.dosage.indicationName = oldIndicationName;
      }
    },
    onChangeCountryName(countryName) {
      const {
        dosage: { onSelectCountryName },
      } = self;

      self.countryName = countryName;

      onSelectCountryName(countryName);
    },
    downloadTherapy: flow(function* downloadTherapy(therapyId) {
      const {
        therapies: { fetchTherapies },
      } = getRoot(self);

      const params = {
        id: therapyId,
      };

      try {
        const { data } = yield axios.get('/therapies/v1/export', { params, responseType: 'blob' });

        yield downloadFile(data, 'therapy.xlsx');

        fetchTherapies();
      } catch (error) {
        // console.log(error);
      }
    }),
  }));

export default TherapiesModel;
