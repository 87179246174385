import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const Loader = ({ size = 32, color = '#DA1B5E', tip }) => {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: size,
        color,
      }}
      spin
    />
  );

  return <Spin tip={tip} indicator={antIcon} />;
};

export default Loader;
