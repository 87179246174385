import React, { useState } from 'react';

import { observer } from 'mobx-react';
import AppSelect from '../ui/AppSelect';
import styles from './styles.module.scss';
import { useRootModel } from '../../models/rootStore';
import TextButton from '../buttons/TextButton';
import NewIndicationModal from '../modals/NewIndicationModal';
import NewDrugModal from '../modals/NewDrugModal';
import EditFilterItemModal from '../modals/EditFilterItemModal';
import DeleteFilterItemModal from '../modals/DeleteFilterItemModal';
import AppButton from '../ui/AppButton';
import NewTherapyModal from '../modals/NewTherapyModal';

const Filters = () => {
  const [newIndicationModalVisible, setNewIndicationModalVisible] = useState(false);
  const [newDrugModalVisible, setNewDrugModalVisible] = useState(false);
  const [editIndicationNameModalVisible, setEditIndicationNameModalVisible] = useState(false);
  const [deleteIndicationModalVisible, setDeleteIndicationModalVisible] = useState(false);
  const [editDrugNameModalVisible, setEditDrugNameModalVisible] = useState(false);
  const [deleteDrugModalVisible, setDeleteDrugModalVisible] = useState(false);
  const [newTherapyModalVisible, setNewTherapyModalVisible] = useState(false);

  const {
    filters: { countries, indications, drugs, canCreateNewTherapy },
  } = useRootModel();

  const onOpenNewIndicationModal = () => {
    setNewIndicationModalVisible(true);
  };

  const onOpenNewDrugModal = () => {
    setNewDrugModalVisible(true);
  };

  const onOpenEditIndicationModal = (indicationName) => {
    setEditIndicationNameModalVisible(true);

    indications.saveIndicationName(indicationName);
  };

  const onOpenDeleteIndicationModal = (indicationName) => {
    setDeleteIndicationModalVisible(true);

    indications.saveIndicationName(indicationName);
  };

  const onOpenDeleteDrugModal = (drugName) => {
    setDeleteDrugModalVisible(true);

    drugs.saveDrugName(drugName);
  };

  const onOpenEditDrugNameModal = (drugName) => {
    setEditDrugNameModalVisible(true);

    drugs.saveDrugName(drugName);
  };

  const onOpenNewTherapyModal = () => {
    setNewTherapyModalVisible(true);
  };

  return (
    <div className={styles.filtersWrapper}>
      <span className={styles.text}>Filters</span>
      <div className={styles.filtersContainer}>
        <AppSelect
          loading={countries.isLoading}
          placeholder='Country'
          legend='Country'
          listItems={countries.list}
          size='large'
          onSearch={countries.fetchCountries}
          onSelectCb={countries.onSelectCountry}
        />
        <div>
          <AppSelect
            loading={indications.isLoading}
            listItems={indications.list}
            placeholder='Indication'
            legend='Indication'
            onEdit={onOpenEditIndicationModal}
            onDelete={onOpenDeleteIndicationModal}
            size='large'
            onSearch={indications.fetchIndications}
            onSelectCb={indications.onSelectIndication}
          />
          <TextButton
            className={styles.filterButton}
            onClick={onOpenNewIndicationModal}
            position='right'
            text='Add new indication'
          />
        </div>

        <div>
          <AppSelect
            loading={drugs.isLoading}
            listItems={drugs.list}
            placeholder='Drug'
            legend='Drug'
            size='large'
            onSearch={drugs.fetchDrugs}
            onSelectCb={drugs.onSelectDrug}
            onEdit={onOpenEditDrugNameModal}
            onDelete={onOpenDeleteDrugModal}
          />
          <TextButton
            className={styles.filterButton}
            onClick={onOpenNewDrugModal}
            position='right'
            text='Add new drug'
          />
        </div>
      </div>

      <div className={styles.bottomActions}>
        {canCreateNewTherapy && (
          <div className={styles.newTherapy}>
            <p>
              Therapy for this country, drug, and indication does not exist yet. Would you like to
              create a new therapy?
            </p>
            <AppButton
              text='Crate new therapy'
              onClick={onOpenNewTherapyModal}
              className={styles.newTherapyButton}
            />
          </div>
        )}
      </div>

      <NewIndicationModal
        visible={newIndicationModalVisible}
        setVisible={setNewIndicationModalVisible}
      />
      <NewDrugModal visible={newDrugModalVisible} setVisible={setNewDrugModalVisible} />
      <NewTherapyModal visible={newTherapyModalVisible} setVisible={setNewTherapyModalVisible} />
      <EditFilterItemModal
        setVisible={setEditIndicationNameModalVisible}
        visible={editIndicationNameModalVisible}
        onEditName={indications.editIndication}
        errorMessage={indications.errorMessage}
        cleanErrors={indications.cleanErrors}
        savedName={indications.savedIndicationName}
        inputLegend='Indication name'
        completeText='Indication name has been changed.'
        title='Edit indication name'
      />
      <EditFilterItemModal
        setVisible={setEditDrugNameModalVisible}
        visible={editDrugNameModalVisible}
        onEditName={drugs.editDrug}
        errorMessage={drugs.errorMessage}
        cleanErrors={drugs.cleanErrors}
        savedName={drugs.savedDrugName}
        inputLegend='Drug name'
        completeText='Drug name has been changed.'
        title='Edit drug name'
        placeholder='Drug name'
        fieldName='drug'
      />
      <DeleteFilterItemModal
        onDelete={indications.deleteIndication}
        setVisible={setDeleteIndicationModalVisible}
        visible={deleteIndicationModalVisible}
        savedName={indications.savedIndicationName}
        saveName={indications.saveIndicationName}
        subTitle='Do you really want to delete this indication? This action cannot be undone.'
        completeText='Indication has been deleted.'
      />

      <DeleteFilterItemModal
        onDelete={drugs.deleteDrug}
        setVisible={setDeleteDrugModalVisible}
        visible={deleteDrugModalVisible}
        savedName={drugs.savedDrugName}
        saveName={drugs.saveDrugName}
        subTitle='Do you really want to delete this drug? This action cannot be undone.'
        completeText='Drug has been deleted.'
      />
    </div>
  );
};

export default observer(Filters);
