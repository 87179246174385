import React, { useState } from 'react';
import { observer } from 'mobx-react';
import ModalBase from '../ModalBase';
import NewIndicationForm from '../../forms/NewIndicationForm';

import styles from './styles.module.scss';
import { useRootModel } from '../../../models/rootStore';
import Complete from '../../Complete';

const NewIndicationModal = ({ visible, setVisible }) => {
  const {
    filters: { createIndication, errorMessage, cleanErrors },
  } = useRootModel();

  const [step, setStep] = useState(1);

  const onClose = () => {
    setVisible(false);
    setStep(1);
    cleanErrors();
  };

  return (
    <ModalBase showCloseIcon={step !== 2} width={404} visible={visible} onClose={onClose}>
      {step === 1 ? (
        <div className={styles.container}>
          <h2>New Indication</h2>

          <NewIndicationForm
            errorMessage={errorMessage}
            cleanErrors={cleanErrors}
            onClose={onClose}
            setStep={setStep}
            inputLegend='Indication name'
            onCreateIndication={createIndication}
          />
        </div>
      ) : (
        <Complete
          text='Indication has been added to your list and can now be used.'
          onClose={onClose}
        />
      )}
    </ModalBase>
  );
};

export default observer(NewIndicationModal);
