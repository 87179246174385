import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { customHistory } from '../../history';
import MainRouter from '../../routes/MainRouter';
import AxiosConfig from '../../AxiosConfig';
import { useRootModel } from '../../models/rootStore';
import { CustomRouter } from '../CustomRouter';

const MainApp = () => {
  AxiosConfig();

  const {
    login: { login, isAuth, isLoading },
  } = useRootModel();

  const pathName = window.location.pathname;

  useEffect(() => {
    if (pathName === '/start') {
      login(() => {
        customHistory.push('/');
      });
    }
  }, [pathName]);

  return (
    <div className='App'>
      <CustomRouter history={customHistory}>
        <MainRouter isAuth={isAuth} isLoading={isLoading} />
      </CustomRouter>
    </div>
  );
};

export default observer(MainApp);
