import React from 'react';
import { Modal } from 'antd';
import cn from 'classnames';

import { ReactComponent as CloseIcon } from '../../../assets/img/close-modal.svg';

import styles from './styles.module.scss';

const ModalBase = ({ children, width, visible, onClose, showCloseIcon = true }) => {
  return (
    <Modal
      footer={null}
      className={cn(styles.modal)}
      bodyStyle={{ padding: 0 }}
      closable={false}
      onOk={onClose}
      destroyOnClose
      onCancel={onClose}
      visible={visible}
      centered
      width={width}
      maskClosable={false}
    >
      <>
        {showCloseIcon && <CloseIcon onClick={onClose} className={styles.closeIcon} />}
        {children}
      </>
    </Modal>
  );
};

export default ModalBase;
