import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import NotFound from '../components/NotFound';
import Home from '../pages/Home';
import Login from '../pages/Login';

const MainRouter = ({ isAuth, isLoading }) => {
  return (
    <Routes>
      <Route path='/' element={<Home isAuth={isAuth} isLoading={isLoading} />} />
      <Route path='start' element={<Login isAuth={isAuth} />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
};

export default observer(MainRouter);
