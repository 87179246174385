import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useRootModel } from '../../models/rootStore';

import { ReactComponent as LogoutButton } from '../../assets/img/logout-btn.svg';

import styles from './styles.module.scss';
import ModalBase from '../modals/ModalBase';
import AppButton from '../ui/AppButton';

const Logout = () => {
  const [logoutModal, setLogoutModal] = useState(false);

  const {
    login: { logout },
  } = useRootModel();

  const onLogout = () => {
    logout();
  };

  const toggleModal = () => {
    setLogoutModal((prev) => !prev);
  };

  return (
    <>
      <button className={styles.button} type='button' onClick={toggleModal}>
        <LogoutButton />
      </button>

      <ModalBase visible={logoutModal} width={360} onClose={toggleModal}>
        <div className={styles.container}>
          <h3>Are you sure you want to logout?</h3>

          <div className={styles.buttonsContainer}>
            <AppButton text='Confirm' onClick={onLogout} />
            <AppButton text='Cancel' onClick={toggleModal} />
          </div>
        </div>
      </ModalBase>
    </>
  );
};

export default observer(Logout);
