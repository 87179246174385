import React from 'react';
import { useFormik } from 'formik';

import InputBase from '../../InputBase';
import AppButton from '../../ui/AppButton';

import styles from './styles.module.scss';

const CommentForm = ({ valueComment, stopCommentEditing, saveComment, therapyId }) => {
  const { handleSubmit, handleChange, values } = useFormik({
    initialValues: {
      comment: valueComment,
    },
    onSubmit: (value) => {
      saveComment(therapyId, value.comment, () => {
        stopCommentEditing();
      });
    },
  });

  return (
    <form autoComplete='off' className={styles.container} onSubmit={handleSubmit}>
      <InputBase
        className={styles.inputContainer}
        id='comment'
        autoFocus
        name='comment'
        type='text'
        value={values.comment}
        onChange={handleChange}
      />

      <div className={styles.buttonsContainer}>
        <AppButton
          className={styles.button}
          size='small'
          onClick={stopCommentEditing}
          color='secondary'
          text='Cancel'
        />
        <AppButton submit className={styles.button} size='small' text='Comment' />
      </div>
    </form>
  );
};

export default CommentForm;
