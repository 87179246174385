import React from 'react';
import { Dropdown, Menu } from 'antd';

import { observer } from 'mobx-react';

import { ReactComponent as Actions } from '../../assets/img/outline_dots.svg';

import styles from './styles.module.scss';

const ActionBar = ({
  startEditComment,
  onClickDelete,
  onClickViewAssumption,
  onClickEditSku,
  onClickEditIndication,
}) => {
  const menu = (
    <Menu>
      <Menu.Item key={1}>
        <button onClick={onClickViewAssumption} type='button'>
          View assumptions
        </button>
      </Menu.Item>
      <Menu.Item key={2}>
        <button onClick={onClickEditSku} type='button'>
          Edit Sku
        </button>
      </Menu.Item>
      <Menu.Item key={3}>
        <button onClick={onClickEditIndication} type='button'>
          Edit Indication/dosing
        </button>
      </Menu.Item>
      <Menu.Item key={4}>
        <button onClick={startEditComment} type='button'>
          Add comment
        </button>
      </Menu.Item>
      <Menu.Item key={5}>
        <button onClick={onClickDelete} type='button'>
          Delete
        </button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      placement='bottomRight'
      overlayClassName={styles.container}
      trigger={['click']}
      overlay={menu}
    >
      <Actions />
    </Dropdown>
  );
};

export default observer(ActionBar);
