import React, { useState } from 'react';

import { observer } from 'mobx-react';
import ModalBase from '../ModalBase';
import styles from './styles.module.scss';
import NewIndicationForm from '../../forms/NewIndicationForm';
import Complete from '../../Complete';

const EditFilterItemModal = ({
  visible,
  setVisible,
  onEditName,
  cleanErrors,
  errorMessage,
  savedName,
  completeText,
  title,
  inputLegend,
  placeholder,
  fieldName,
}) => {
  const [step, setStep] = useState(1);

  const onClose = () => {
    setVisible(false);
    setStep(1);
    cleanErrors();
  };

  const onEdit = (name, onEditCallback) => {
    onEditName(name, onEditCallback);
  };

  return (
    <ModalBase showCloseIcon={step !== 2} width={404} visible={visible} onClose={onClose}>
      {step === 1 ? (
        <div className={styles.container}>
          <h2>{title}</h2>

          <NewIndicationForm
            inputLegend={inputLegend}
            defaultValue={savedName}
            onEditIndication={onEdit}
            errorMessage={errorMessage}
            cleanErrors={cleanErrors}
            onClose={onClose}
            setStep={setStep}
            placeholder={placeholder}
            name={fieldName}
          />
        </div>
      ) : (
        <Complete text={completeText} onClose={onClose} />
      )}
    </ModalBase>
  );
};

export default observer(EditFilterItemModal);
