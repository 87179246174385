export default function numberWithCommas(x) {
  if (!x) {
    return 0;
  }
  let number;

  const numberSplit = x.toString().split('.');

  let numberAfterComas;

  if (numberSplit[1] !== undefined) {
    // eslint-disable-next-line prefer-destructuring
    numberAfterComas = numberSplit[1].length === 1 ? `${numberSplit[1]}0` : numberSplit[1];

    number = `${x
      .toLocaleString('en-US')
      .replaceAll(',', '.')
      .slice(0, numberSplit[1].length === 1 ? -2 : -3)},${numberAfterComas}`;
  } else {
    number = x.toLocaleString('en-US').replaceAll(',', '.');
  }

  return number;
}
