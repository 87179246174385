import { flow, types as t, getRoot } from 'mobx-state-tree';
import axios from 'axios';

const DrugFilterModel = t
  .model('DrugFilterModel', {
    list: t.optional(t.array(t.string), []),
    isLoading: t.optional(t.boolean, false),
    selectedDrug: t.optional(t.string, ''),
    savedDrugName: t.optional(t.string, ''),
  })
  .actions((self) => ({
    fetchDrugs: flow(function* fetchDrugs(drugName) {
      try {
        self.isLoading = true;

        const params = {
          drugName,
        };

        const { data } = yield axios.get('/drugs/v1/matching', { params });

        self.list = data;
      } catch (error) {
        //
      } finally {
        self.isLoading = false;
      }
    }),
    cleanErrors() {
      self.errorMessage = '';
    },
    onSelectDrug(drugName) {
      self.selectedDrug = drugName;

      const {
        therapies: { fetchTherapies, changePage },
      } = getRoot(self);

      changePage(0);
      fetchTherapies();
    },
    saveDrugName(drugName) {
      self.savedDrugName = drugName;
    },
    editDrug: flow(function* editDrug(drugName, onSuccessCb) {
      try {
        const { savedDrugName, saveDrugName } = self;

        const {
          therapies: { fetchTherapies },
        } = getRoot(self);

        const config = {
          newName: drugName,
          oldName: savedDrugName,
        };

        yield axios.patch('/drugs/v1/name', config);

        onSuccessCb();

        saveDrugName('');

        fetchTherapies();
      } catch (error) {
        self.errorMessage = error?.response?.data?.detail || error.message || 'Server error';
      } finally {
        self.isLoading = false;
      }
    }),
    deleteDrug: flow(function* deleteDrug(onSuccessCb) {
      const { savedDrugName } = self;
      const {
        therapies: { fetchTherapies },
      } = getRoot(self);

      try {
        const params = {
          name: savedDrugName,
        };

        yield axios.delete('/drugs/v1', { params });

        onSuccessCb();
        fetchTherapies();
      } catch (error) {
        self.errorMessage = error?.response?.data?.detail || error.message || 'Server error';
      }
    }),
  }));

export default DrugFilterModel;
