import { flow, getParent, types } from 'mobx-state-tree';
import axios from 'axios';
import TherapyModel from './TherapyModel';
import PaginationModel from '../common/PaginationModel';

const TherapiesModel = types
  .model('TherapiesModel', {
    therapies: types.array(TherapyModel),
    pagination: types.optional(PaginationModel, {}),
    isLoading: types.optional(types.boolean, false),
    error: types.maybeNull(types.string),
    sortOrder: types.optional(types.string, 'asc'),
    sortBy: types.optional(types.string, 'default'),
    selectedTherapyId: types.optional(types.maybeNull(types.number), null),
  })
  .actions((self) => ({
    fetchTherapies: flow(function* fetchTherapies() {
      const { pagination, sortBy, sortOrder } = self;
      self.isLoading = true;

      const {
        filters: { countries, indications, drugs },
      } = getParent(self);

      const params = {
        itemsOnPage: pagination.itemsOnPage,
        pageNumber: pagination.pageNumber,
        countryName: countries.selectedCountry,
        indicationName: indications.selectedIndication,
        drugName: drugs.selectedDrug,
        sortBy,
        sortOrder,
      };
      try {
        const { data } = yield axios.get('/therapies/v1', { params });
        self.therapies = data.data;

        pagination.size = data.pageable.size;
        pagination.pageNumber = data.pageable.pageNumber;
        pagination.totalElements = data.pageable.totalElements;
        pagination.totalPages = data.pageable.totalPages;

        if (data.pageable.empty) {
          pagination.pageNumber = 0;
        }
      } catch (e) {
        self.error = e.response?.data.message;
      } finally {
        self.isLoading = false;
      }
    }),
    deleteTherapy: flow(function* deleteTherapy(id, onSuccessCb) {
      const params = {
        id,
      };

      const { fetchTherapies, therapies, pagination } = self;

      try {
        yield axios.delete('therapies/v1', { params });

        if (therapies.length === 1 && pagination.pageNumber !== 0) {
          self.pagination.pageNumber = pagination.pageNumber - 1;

          fetchTherapies();
        } else {
          fetchTherapies();
        }

        onSuccessCb();
      } catch (error) {
        // console.log(error.message);
      }
    }),
    handlePageChange(page, size) {
      const { pagination, fetchTherapies } = self;
      pagination.pageNumber = page - 1;
      pagination.size = size;
      fetchTherapies();
    },
    changePage(page) {
      const { pagination } = self;

      pagination.pageNumber = page;
    },
    setSortBy(sortName) {
      const { fetchTherapies } = self;

      if (sortName === self.sortBy && self.sortOrder === 'asc') {
        self.sortOrder = 'desc';
      } else {
        self.sortOrder = 'asc';
      }

      self.sortBy = sortName;

      fetchTherapies();
    },
    setSelectedTherapyId(id) {
      self.selectedTherapyId = id;
    },
    cleanSelectedTherapyId() {
      self.selectedTherapyId = null;
    },
  }))
  .views((self) => ({
    get selectedTherapy() {
      const { therapies, selectedTherapyId } = self;

      return therapies.find((item) => item.id === selectedTherapyId);
    },
  }));

export default TherapiesModel;
