import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toJS } from 'mobx';

import { observer } from 'mobx-react';
import ModalBase from '../ModalBase';

import styles from './styles.module.scss';
import InputBase from '../../InputBase';
import { useRootModel } from '../../../models/rootStore';
import Dosing from '../../Dosing';
import AppButton from '../../ui/AppButton';
import Complete from '../../Complete';
import AppSelect from '../../ui/AppSelect';
import ConfirmCloseModal from '../ConfirmCloseModal';

const validationSchema = Yup.object({
  phases: Yup.array().of(
    Yup.object().shape({
      activeIngredientAmount: Yup.number().min(0.1).required(),
      frequency: Yup.number().min(0.1).required(),
      frequencyDuration: Yup.string().required(),
      numberOfTimes: Yup.number().min(0.1).required(),
      periodDuration: Yup.string().required(),
      periodNumber: Yup.number().min(0.1).required(),
      unit: Yup.string().required(),
    }),
  ),
  indicationName: Yup.string().required(),
});

const EditIndicationModal = ({ visible, setVisible }) => {
  const initState = {
    phases: [],
    indicationName: '',
  };

  const [initialState, setInitialState] = useState(initState);

  const [step, setStep] = useState(1);

  const {
    therapies: { selectedTherapy, fetchTherapies },
    filters: { indications },
    app: { showConfirmCloseModal },
  } = useRootModel();

  const onSaveIndication = () => {
    const { saveIndicationDrug } = selectedTherapy;

    saveIndicationDrug(() => {
      setStep(2);
    });
  };

  const {
    handleSubmit,
    values,
    setErrors,
    validateField,
    errors,
    setFieldValue,
    validateForm,
    resetForm,
  } = useFormik({
    initialValues: initState,
    validationSchema,
    onSubmit: async () => {
      try {
        await validateForm();

        onSaveIndication();
      } catch (err) {
        // console.log(err);
      }
    },
  });

  const validateFieldCb = async (fieldName) => {
    await validateField(fieldName);
  };

  useEffect(() => {
    if (selectedTherapy && visible) {
      if (selectedTherapy.dosage.indicationName) {
        setFieldValue('indicationName', selectedTherapy.dosage.indicationName, false);
        setInitialState((prev) => ({
          ...prev,
          indicationName: selectedTherapy.dosage.indicationName,
        }));
      }

      if (selectedTherapy.dosage.phases.length > 0) {
        setFieldValue('phases', selectedTherapy.dosage.phases, false);
        setInitialState((prev) => ({
          ...prev,
          phases: toJS(selectedTherapy.dosage.phases),
        }));
      }
    }
  }, [selectedTherapy, visible]);

  useEffect(() => {
    if (
      selectedTherapy &&
      selectedTherapy.dosage.phases.length === 0 &&
      values.indicationName !== ''
    ) {
      validateForm();
    }
  }, [values, selectedTherapy]);

  useEffect(() => {
    if (selectedTherapy) {
      const {
        dosage: { setOldPhases, setOldValues },
      } = selectedTherapy;

      setOldPhases();
      setOldValues();
    }
  }, [selectedTherapy]);

  if (!selectedTherapy) {
    return null;
  }

  const onClose = (needDiscardChanges = true) => {
    setVisible(false);

    if (needDiscardChanges) {
      selectedTherapy.dosage.cleanPhases();
      selectedTherapy.setFieldsByDefault();
    }

    setStep(1);
    setErrors({});
    resetForm({
      phases: [],
      indicationName: '',
    });

    fetchTherapies();
  };

  const onClickClose = () => {
    const isSame =
      JSON.stringify({
        ...initialState,
        phases: initialState.phases.map(({ active, ...keepAttrs }) => keepAttrs),
      }) ===
      JSON.stringify({
        ...values,
        phases: toJS(values.phases).map(({ active, ...keepAttrs }) => keepAttrs),
      });

    if (isSame) {
      onClose();
    } else {
      showConfirmCloseModal();
    }
  };

  const { onSubmitLoading } = selectedTherapy;

  return (
    <ModalBase visible={visible} width={step === 1 ? 1408 : 404} onClose={onClickClose}>
      <ConfirmCloseModal onClose={onClose} />
      {step === 1 ? (
        <div className={styles.container}>
          <h2>Edit dosing</h2>

          <form onSubmit={handleSubmit}>
            <div className={styles.headerContainer}>
              <InputBase
                className={styles.inputHeader}
                placeholder='Drug name'
                legend='Drug name'
                name='drugName'
                id='drugName'
                type='text'
                readOnly
                value={selectedTherapy.drugName}
              />

              <AppSelect
                defaultValue={values.indicationName}
                error={errors.indicationName}
                fieldsetClassName={styles.inputHeader}
                loading={indications.isLoading}
                listItems={indications.list}
                placeholder='Indication'
                legend='Indication'
                size='large'
                onSearch={indications.fetchIndications}
                onSelectCb={async (value) => {
                  await setFieldValue('indicationName', value, false);

                  await validateFieldCb('indicationName');

                  selectedTherapy.dosage.onSelectIndicationName(value);
                }}
                onCleanCb={selectedTherapy.dosage.onSelectIndicationName}
                onChange={(value) => {
                  selectedTherapy.dosage.onSelectIndicationName(value);
                  setFieldValue('indicationName', value, false);
                }}
              />
            </div>

            <Dosing
              onValidate={validateForm}
              errors={errors.phases}
              phases={selectedTherapy.dosage.phases}
              deletePhase={selectedTherapy.dosage.deletePhase}
              addNewPhase={() => {
                let needDoActive;

                if (selectedTherapy && selectedTherapy.dosage.phases.length === 0) {
                  needDoActive = false;
                  setFieldValue('phases', selectedTherapy.dosage.phases, false);
                }

                selectedTherapy.dosage.addNewPhase({ needActive: needDoActive });
              }}
              changeActive={selectedTherapy.dosage.changeActive}
            />

            <div className={styles.footerContainer}>
              <AppButton
                className={styles.button1}
                onClick={onClickClose}
                color='secondary'
                text='Cancel'
              />
              <AppButton className={styles.button2} submit loading={onSubmitLoading} text='Save' />
            </div>
          </form>
        </div>
      ) : (
        <Complete onClose={onClose} text='Indication/dosage has been successfully changed.' />
      )}
    </ModalBase>
  );
};

export default observer(EditIndicationModal);
