import React, { useEffect } from 'react';

import { Pagination } from 'antd';
import { observer } from 'mobx-react';
import Header from '../../components/Header';
import Filters from '../../components/Filters';
import date from '../../utils/date';
import styles from './styles.module.scss';
import Table from '../../components/Table';
import { useRootModel } from '../../models/rootStore';
import Loader from '../../components/Loader';

const Home = ({ isAuth, isLoading }) => {
  const {
    therapies: { pagination, handlePageChange, fetchTherapies },
  } = useRootModel();

  const onPageChange = (page, size) => {
    handlePageChange(page, size);
  };

  useEffect(() => {
    if (!isLoading) {
      fetchTherapies();
    }
  }, [isLoading, isAuth]);

  if (!isAuth) {
    return (
      <div className={styles.loader}>
        <Loader size={60} />
      </div>
    );
  }

  return (
    <section>
      <Header />
      <div className={styles.body}>
        <div className={styles.tableWrapper}>
          <Filters />
          <Table />
        </div>
        <div className={styles.footer}>
          <span className={styles.copyrightText}>
            Copyright {date.currentYear}. Simon-Kucher Engine
          </span>
          <Pagination
            onChange={onPageChange}
            size='small'
            showQuickJumper
            current={pagination.pageNumber + 1}
            total={pagination.totalElements}
            defaultPageSize={pagination.size}
          />
        </div>
      </div>
    </section>
  );
};

export default observer(Home);
