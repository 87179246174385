import { flow, getRoot, types } from 'mobx-state-tree';
import axios from 'axios';

const deleteToken = () => {
  delete axios.defaults.headers.common.Authorization;
};

const AuthUserModel = types
  .model('AuthUserModel', {
    isAuth: types.optional(types.boolean, false),
    isLoading: types.optional(types.boolean, false),
    error: types.maybeNull(types.string),
  })
  .actions((self) => ({
    login: flow(function* login(onFail) {
      self.isLoading = true;
      try {
        const { data } = yield axios.post('/token', undefined);
        if (data.id_token) {
          localStorage.setItem('ls_token', data.id_token);
        }

        self.isAuth = true;
      } catch (e) {
        if (e.response?.data.status === 403 && e.response?.data.detail === 'Cannot get token') {
          onFail();
        }
        self.error = e.response?.data.message;
      } finally {
        self.isLoading = false;
      }
    }),
    logout() {
      const { reset } = getRoot(self);

      localStorage.removeItem('ls_token');
      deleteToken();
      self.isAuth = false;

      reset();
    },
  }));

export default AuthUserModel;
