import { types as t, flow, getRoot } from 'mobx-state-tree';
import axios from 'axios';

import PhasesModel from './PhasesModel';

const DosageModel = t
  .model('DosageModel', {
    countryName: t.maybeNull(t.string),
    indicationName: t.maybeNull(t.string),
    currency: t.optional(t.string, ''),
    drugName: t.maybeNull(t.string),
    id: t.optional(t.maybeNull(t.number), null),
    phases: t.array(PhasesModel),
    oldPhases: t.array(PhasesModel),
    oldCountryName: t.maybeNull(t.string),
    oldIndicationName: t.maybeNull(t.string),
  })
  .actions((self) => ({
    getCurrency: flow(function* getCurrency(countryName) {
      const {
        drugCreating: { changeCurrencyForAllSkus },
      } = getRoot(self);

      try {
        const params = {
          countryName,
        };

        const { data } = yield axios.get('/countries/v1/currency', { params });

        changeCurrencyForAllSkus(data);

        self.currency = data;
      } catch (error) {
        //
      }
    }),
    onSelectCountryName(countryName) {
      const {
        drugCreating: { cleanSkus },
      } = getRoot(self);

      const { getCurrency } = self;

      self.countryName = countryName;

      if (countryName && countryName.length > 0) {
        getCurrency(countryName);
      }

      cleanSkus();
    },
    onSelectIndicationName(indicationName) {
      self.indicationName = indicationName;
    },
    changeActive() {
      self.phases.forEach((item) => {
        item.setDisable();
      });
    },
    setOldValues() {
      const { countryName, indicationName } = self;

      self.oldCountryName = countryName;
      self.oldIndicationName = indicationName;
    },
    onSelectDrugName(drugName) {
      self.drugName = drugName;
    },
    cleanFields() {
      self.countryName = null;
      self.indicationName = null;
      self.drugName = null;
      self.phases = [];
    },
    cleanPhases() {
      const { oldPhases } = self;

      if (oldPhases.length >= 0) {
        self.phases = oldPhases.map((item) => ({ ...item }));
        // self.oldPhases = [];
      }
    },
    setOldPhases() {
      const { phases } = self;

      self.oldPhases = phases.map((item) => ({ ...item }));
    },
    addNewPhase({ needActive }) {
      const { phases } = self;

      const phasesLength = phases.length;

      if (phasesLength < 8) {
        const phase = {
          activeIngredientAmount: null,
          frequency: null,
          frequencyDuration: null,
          name: `Phase ${phasesLength + 1}`,
          numberOfTimes: null,
          periodDuration: null,
          unit: null,
          periodNumber: null,
          active: !!needActive,
        };

        phases.push(phase);
      }
    },
    deletePhase(phaseName) {
      const { phases } = self;

      self.phases = phases.filter((phase) => phase.name !== phaseName);

      self.phases = phases.map((item, index) => ({
        ...item,
        name: `Phase ${index + 1}`,
      }));
    },
  }))
  .views((self) => ({
    get isScrapperAvailable() {
      const { countryName, drugName } = self;

      return countryName !== null && countryName !== '' && drugName !== null;
    },
  }));

export default DosageModel;
