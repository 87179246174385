import React from 'react';
import { DatePicker as AntdDatePicker } from 'antd';
import moment from 'moment';

import styles from './styles.module.scss';

const dateFormat = 'DD/MM/YYYY';

// const disabledDate = (current) => {
//   // Can not select days before today and today
//   return current && current < moment().endOf('day');
// };

const DatePicker = ({ date, onSelect }) => {
  return (
    <fieldset className={styles.fieldset}>
      <legend className={styles.legend}>Today’s date</legend>
      <AntdDatePicker
        // disabledDate={disabledDate}
        allowClear={false}
        defaultValue={moment(date, dateFormat)}
        format={dateFormat}
        bordered={false}
        suffixIcon={null}
        onChange={(value) => {
          onSelect(value.format(dateFormat));
        }}
      />
    </fieldset>
  );
};

export default DatePicker;
